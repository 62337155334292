import React, { useEffect, useState } from 'react'
import './Dashboard.scss'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, BarElement } from "chart.js";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { faker } from '@faker-js/faker';
import { getUser } from '../home/Home';
import { useNavigate } from 'react-router-dom';
import { useGetDashboardDataQuery } from '../features/product/productSlice';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Dashboard() { 
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(()=>{
    if(!user){
      navigate('/login')
    }
  },[user])
  //login end

  const { data:dashboardData, isLoading, isFetching } = useGetDashboardDataQuery({bearerToken: user});
  console.log(dashboardData)
  return (
    <div className='dashboard'>
        <div className="count-cards">
            <div className="count-card top-cards">
                <div className="title">Frames Available</div>
                <div className="count">{dashboardData?.data?.totalInventoryQuantity}</div>
            </div>
            <div className="count-card top-cards">
                <div className="title">Frames Sold</div>
                <div className="count">{dashboardData?.data?.totalOrders}</div>
            </div>
            <div className="count-card top-cards">
                <div className="title">Customers</div>
                <div className="count">{dashboardData?.data?.totalCustomers}</div>
            </div>
            <div className="count-card top-cards">
                <div className="title">Return | Exchange</div>
                <div className="count">{dashboardData?.data?.totalReturnCount} | {dashboardData?.data?.totalExchangeCount}</div>
            </div>
        </div>
        {/* <div className="second-row">
            <div className="count-card">
                <div className="title mont-bold">Total Customers</div>
                <div className="line-chart-box">
                    <CustomersChart/>
                </div>
            </div>
            <div className="count-card">
                <div className="title mont-bold">Bestselling Frame</div>
                <div className='doughnut-box'>
                    <BestSellingChart/>
                </div>
            </div>
        </div> */}
        {/* <div className="third-row">
            <div className="count-card">
                <div className="title mont-bold">Frames Available</div>
                <div className="bar-chart-box">
                    <FramesChart/>
                </div>
            </div>
        </div> */}
        <div className='fourth-row'>
          <div className="count-card">
            <div className="title mont-bold">Bestselling Frames</div>
            <div className="bestselling-frames">
              {/* <div className="frame">
                <img src="https://res.cloudinary.com/dcjc2b8ml/image/upload/if_w_gt_400/w_400/if_end/v1716885197/product/P801555519-146C1.png" alt="Frame" />
                <div className="frame-name">Netre Air Glasses</div>
                <div className="sku">23214</div>
              </div> */}
              {dashboardData?.data?.bestSellingProducts && dashboardData?.data?.bestSellingProducts.map((product, index) => (
                <div className="frame" key={index}>
                  <img src={product.image} alt="Frame" />
                  <div className="frame-name">{product.name}</div>
                  <div className="sku mont-bold">SKU:&nbsp;{product.sku}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
    </div>
  )
}

export default Dashboard

const BestSellingChart = () => {
    const data = {
    labels: ['Netre Air Glasses', 'Netre Polars', 'Netre Bloom Glasses', 'Other'],
    datasets: [
        {
            label: '% of Frames',
            data: [38.6, 30.8, 22.5, 8.1],
            backgroundColor: [
            '#A9772B',
            '#1F9FA7',
            '#001246',
            '#629ECA',
            ],
            borderRadius: 4,
            // borderColor: [
            //   'rgba(255, 99, 132, 1)',
            //   'rgba(54, 162, 235, 1)',
            //   'rgba(255, 206, 86, 1)',
            //   'rgba(75, 192, 192, 1)',
            // ],
            // borderWidth: 1,
        },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 30
        },
        plugins: {
        legend: {
            display: true,
            position: 'right',
        },
        },
    };
    return (
        <Doughnut data={data} options={options}/>
    )
}

const CustomersChart = () => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          }
        },
        scales: {
            x: {
              grid: {
                display: false
              },
              border: {
                display: false,
              },
            },
            y: {
                grid: {
                    display: false
                },
                border: {
                    display: false,
                  },
            }
          },
          
      };
      
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      
      const data = {
        labels,
        datasets: [
          {
            label: 'Online',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 400 })),
            borderColor: 'rgb(0,0,0, 1)',
            backgroundColor: 'rgba(0,0,0, 0.5)',
            borderWidth: 1,
            tension: 0.5
          },
          {
            label: 'In Store',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 400 })),
            borderColor: 'rgb(0,0,0, 0.5)',
            backgroundColor: 'rgba(0,0,0, 0.2)',
            borderDash: [3,3],
            borderWidth: 1,
            tension: 0.5
          },
        ],
      };
    return (
        <Line options={options} data={data} />
    )
}

const FramesChart = () => {
    const labels = ['Air', 'Bloom', 'Sun', 'BlueRim', 'Panache', 'JPolar', 'Wall', 'Boss', 'Maverick', 'Beach'];
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
            x: {
                grid: {
                    display: false,
              },
              border: {
                display: false,
              },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                  },
            },
          },
      };
    const data = {
    labels,
    datasets: [
        {
        data: labels.map(() => faker.datatype.number({ min: 0, max: 50 })),
        backgroundColor: [
        '#A9772B',
        '#1F9FA7',
        '#001246',
        '#629ECA',
        '#444444'
        ], 
        barThickness: 25,
        borderRadius: 4
        }
    ],
    };
    
    return <Bar options={options} data={data} />;
}