import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderId: null,
    varient: null,
  },
  reducers: {
    setOrder: (state, action) => {
      console.log('setOrder', action.payload);
      state.orderId = action.payload;
    },
    setVarient: (state, action) => {
      console.log('setVarient', action.payload)
      state.varient = action.payload;
    },
    removeOrder: (state, action) => {
        state.orderId = null;
        state.varient = null;
    },
  },
});

export const { setOrder, setVarient, removeOrder} = orderSlice.actions;
export default orderSlice.reducer;
