import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGetCustomerOrderOfRetailerQuery } from '../../features/order/cutomerOrderSlice';
import { getUser } from '../../home/Home';
import { OrderCard } from '../completedOrders/CompletedOrders';

function CurrentOrders() {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(()=>{
    if(!user){
      navigate('/login')
    }
  },[user])
  //login end
  
  const [productStatus, setProductStatus] = useState('In Making,Prepared,Placed');
  const {
    data: CustomerOrderOfRetailerData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCustomerOrderOfRetailerQuery({productStatus: productStatus ,bearerToken:user})
  if(isError && error.status === 401){
    navigate('/login');
  }
  const [orderType,  setOrderType] = useState("All");
  const [selectedTab, setSelectedTab] = useState('All');
  const tabNames = ['All', 'In Making', 'Prepared', 'Placed'];
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setOrderType(tab);
    switch (tab) {
      case 'All':
        setProductStatus('In Making,Prepared,Placed');
        break;
      case 'In Making':
        setProductStatus('In Making');
        break;
      case 'Prepared':
        setProductStatus('Prepared');
        break;
      case 'Placed':
        setProductStatus('Placed');
        break;
      default:
        break;
    }
  };

  return (
    <div className='CompletedOrders'>
      {/* navigation */}
      <div className="container">
        <div className="store-types">
        {tabNames.map(tab => (
          <div
            key={tab}
            className={orderType === tab ? 'store-type active' : 'store-type'}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
        <div className="border"></div>
      </div>
      </div>
      <div className="orders">
      {CustomerOrderOfRetailerData?.data && [...CustomerOrderOfRetailerData.data].reverse().map(item => {
            return item.cart.map((cartItem, index) => {
              if (index === 0) {
                return <OrderCard data={{...item, cart: [cartItem]}} key={item._id} />
              } else {
                return <OrderCard data={{...item, cart: [cartItem]}} key={item._id + index} />
              }
            })
        })}
        {CustomerOrderOfRetailerData?.data && CustomerOrderOfRetailerData?.data.length == "0" && <p>No Products To Show.</p>}
        {isLoading && <p>Loading...</p>}
      </div>
    </div>
  )
}

export default CurrentOrders

