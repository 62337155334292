import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
export const productSlice=createApi({
    reducerPath:'products',
    baseQuery:fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL }),
    tagTypes:['InventoryUpdated'],
    endpoints:(builder)=>({
        // without pagination
        // getProducts:builder.query({
        //     query:()=>{
        //         return{
        //             url:"/netre/products",
        //             headers: {
        //                 'x-api-key': process.env.REACT_APP_API_KEY,
        //             }}
        //         },
        // }),
        // pagination
        getProducts: builder.query({
              query: (page) => {
                return {
                  url: `/netre/products?page=${page}&limit=10`,
                  headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                  }
                }
              },
              // Only have one cache entry because the arg always maps to one string
            //   serializeQueryArgs: ({ endpointName }) => {
            //     return endpointName;
            //   },
            //   serializeQueryArgs: ({ endpointName, queryArgs }) => {
            //     return `${endpointName}-${queryArgs.page}`;
            //   },
              // Always merge incoming data to the cache entry
            //   merge: (currentCache, newItems) => {
            //     console.log(currentCache, newItems)
            //     // dont push the same data again
            //     currentCache.products.push(...newItems.products);
            //   },
            // merge: (currentCache, newItems) => {
            //     const existingIds = new Set(currentCache.products.map(product => product.id));
            //     const uniqueNewProducts = newItems.products.filter(product => !existingIds.has(product.id));
            //     currentCache.products.push(...uniqueNewProducts);
            //   },
              // Refetch when the page arg changes
              forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;  
              }
            }),
            // getProducts: builder.query({
            //     query: (page) => {
            //       return {
            //         url: `/products?page=${page}&limit=10`,
            //         headers: {
            //           'x-api-key': process.env.REACT_APP_API_KEY
            //         }
            //       }
            //     },
            //     serializeQueryArgs: ({ endpointName, queryArgs }) => {
            //       return `${endpointName}-${queryArgs.page}`;
            //     },
            //     merge: (currentCache, newItems) => {
            //       const existingIds = new Set(currentCache.products.map(product => product.id));
            //       const uniqueNewProducts = newItems.products.filter(product => !existingIds.has(product.id));
            //       currentCache.products.push(...uniqueNewProducts);
            //     },
            //     forceRefetch({ currentArg, previousArg }) {
            //       return currentArg !== previousArg;
            //     }
            //   }),
        getProductData: builder.query({
            query:({id})=>{
                if(id==null) return;
                return{
                    url:`/netre/products/${id}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY
                    }
                }
            }
        }),

        //get retailer order data
        getRetailerOrders:builder.query({
            query:({productStatus, bearerToken})=>{
                return{
                    url:`/retailer/orders?productStatus=${productStatus}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }}
                },
            providesTags:['InventoryUpdated']
        }),

        updateRetailerOrderStatus: builder.mutation({
            query:({body,bearerToken})=>{
                return{
                    url: `/retailer/update-order-status`,
                    method: "PUT",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['InventoryUpdated']
        }),

        //add products to retailer inventory
        addProductsToRetailerInventory: builder.mutation({
            query:({products,bearerToken})=>{
                return{
                    url: "/retailer/add-products",
                    method: "POST",
                    body: products,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['InventoryUpdated']
        }),
        
        // return products from retailer inventory
        returnProductsFromRetailerInventory: builder.mutation({
            query:({body,bearerToken})=>{
                return{
                    url: "/retailer/order/return",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['InventoryUpdated']
        }),

        // exchange products from retailer inventory
        exchangeProductsFromRetailerInventory: builder.mutation({
            query:({body,bearerToken})=>{
                return{
                    url: "/retailer/order/exchange",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['InventoryUpdated']
        }),

        getProductDataBySku: builder.query({
            query:({sku,bearerToken})=>{
                return{
                    url:`/retailer/products/sku/${sku}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),

        fetchProductDataBySku: builder.mutation({
            query:({sku,bearerToken})=>{
                return{
                    url:`/retailer/products/sku/${sku}`,
                    method: "GET",
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),

        getRetailerProducts: builder.query({
            query:({bearerToken})=>{
                // console.log(bearerToken)
                return{
                    url: "retailer/getInventory",
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            },
            providesTags:['InventoryUpdated']
        }),
        getCustomerByMobile: builder.query({
            query:({mobile})=>{
                return{
                    url: `/customer/mobile/${mobile}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                    }
                }
            }
        }),
        getRetailerInfo: builder.query({
            query:({bearerToken})=>{
                return{
                    url: `/retailer/profile`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        getNetreCareData: builder.query({
            query:({bearerToken})=>{
                return{
                    url: "/netre/getNetreCare",
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        getCouponDetailsFromCode: builder.query({
            query:({code, bearerToken})=>{
                return{
                    url: `/netre/getCouponDetailsFromCode/?couponCode=${code}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        generateOTPforCustomerSignup: builder.mutation({
            query:({body, bearerToken})=>{
                return{
                    url: "/customer/generateOTP",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        registerCustomerFromRetailer: builder.mutation({
            query:({body, bearerToken})=>{
                return{
                    url: "/customer/register",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        getDashboardData: builder.query({
            query:({bearerToken})=>{
                return{
                    url: "/retailer/getDashboardData",
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        addInquiry: builder.mutation({
            query:({body, bearerToken})=>{
                return{
                    url: "/netre/addInquiry",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        getInquiry: builder.query({
            query:({bearerToken})=>{
                return{
                    url: "/netre/getInquiry",
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        }),
        getGlobalSearchResults: builder.query({
            query:({searchText, bearerToken})=>{
                return{  
                    url: `/netre/getGlobalSearchResults?searchString=${searchText}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }
                }
            }
        })
    })
})

export const {    
    useGetProductsQuery,
    useGetProductDataQuery,
    useGetRetailerOrdersQuery,
    useUpdateRetailerOrderStatusMutation,
    useGetRetailerProductsQuery,
    useGetCustomerByMobileQuery,
    useGetProductDataBySkuQuery,
    useFetchProductDataBySkuMutation,
    useGetRetailerInfoQuery,
    useAddProductsToRetailerInventoryMutation,
    useReturnProductsFromRetailerInventoryMutation,
    useExchangeProductsFromRetailerInventoryMutation,
    useGetNetreCareDataQuery,
    useGetCouponDetailsFromCodeQuery,
    useGenerateOTPforCustomerSignupMutation,
    useRegisterCustomerFromRetailerMutation,
    useGetDashboardDataQuery,
    useAddInquiryMutation,
    useGetInquiryQuery,
    useGetGlobalSearchResultsQuery
} = productSlice;