import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
export const customerOrderSlice=createApi({
    reducerPath:'customerOrders',
    baseQuery:fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL }),
    tagTypes:['CustomerOrderAdded'],
    endpoints:(builder)=>({
        getCustomerOrderOfRetailer:builder.query({
            query:({bearerToken, productStatus})=>{
                return{
                    url:`/retailer/customer/getOrders?productStatus=${productStatus}`,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }}
                },
                providesTags:['CustomerOrderAdded']
        }),
        addCustomerOrderFromRetailer: builder.mutation({
            query:({order,bearerToken})=>{
                return{
                    url: "/retailer/addOrder",
                    method: "POST",
                    body: order,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['CustomerOrderAdded']
        }),
        getCustomerListOfRetailer:builder.query({
            query:({bearerToken})=>{
                return{
                    url:"/retailer/getCustomers",
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    }}
                },
                providesTags:['CustomerOrderAdded']
        }),
        returnCustomerOrderFromRetailer: builder.mutation({
            query:({body,bearerToken})=>{
                return{
                    url: "/retailer/customer/order/return",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['CustomerOrderAdded']
        }),
        exchangeCustomerOrderFromRetailer: builder.mutation({
            query:({body,bearerToken})=>{
                return{
                    url: "/retailer/customer/order/exchange",
                    method: "POST",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['CustomerOrderAdded']
        }),
        updateCustomerOrderStatusFromRetailer: builder.mutation({
            query:({order_id, body, bearerToken})=>{
                return{
                    url: `/retailer/customer/update-order-status/${order_id}`,
                    method: "PUT",
                    body: body,
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY,
                        "Authorization": `Bearer ${bearerToken}`
                    },
                }
            },
            invalidatesTags:['CustomerOrderAdded']
        }),
    })
})

export const {    
    useGetCustomerOrderOfRetailerQuery,
    useAddCustomerOrderFromRetailerMutation,
    useGetCustomerListOfRetailerQuery,
    useReturnCustomerOrderFromRetailerMutation,
    useExchangeCustomerOrderFromRetailerMutation,
    useUpdateCustomerOrderStatusFromRetailerMutation
} = customerOrderSlice;