// src/redux/slices/selectedProductsSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedProducts: []
};

const selectedProductsSlice = createSlice({
  name: 'selectedProducts',
  initialState,
  reducers: {
    addProduct(state, action) {
      const product = action.payload;
      const existingProduct = state.selectedProducts.find(p => p.product_id === product.product_id);
      if (existingProduct) {
        existingProduct.quantity = product.quantity;
      } else {
        state.selectedProducts.push(product);
      }
    },
    removeProduct(state, action) {
      const productId = action.payload;
      state.selectedProducts = state.selectedProducts.filter(p => p.product_id !== productId);
    },
    clearProducts(state) {
      state.selectedProducts = [];
    }
  }
});

export const { addProduct, removeProduct, clearProducts } = selectedProductsSlice.actions;

export default selectedProductsSlice.reducer;