import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGetCustomerOrderOfRetailerQuery, useUpdateCustomerOrderStatusFromRetailerMutation } from '../../features/order/cutomerOrderSlice';
import { getUser } from '../../home/Home';
import './CompletedOrders.scss'
import chalk from 'chalk';
import { Bounce, toast } from 'react-toastify';
function CompletedOrders() {
   //login
   const [user, setUser] = useState(getUser());
   const navigate = useNavigate();
   useEffect(()=>{
     if(!user){
       navigate('/login')
     }
   },[user])
   //login end
   
   const [productStatus, setProductStatus] = useState('Delivered,Exchanged,Exchange Initiated,Returned,Return Initiated,Cancelled');
   const {
     data: CustomerOrderOfRetailerData,
     isLoading,
     isSuccess,
     isError,
     error,
   } = useGetCustomerOrderOfRetailerQuery({productStatus:productStatus, bearerToken:user})
   if(isError && error.status === 401){
    navigate('/login');
  }
  console.log(CustomerOrderOfRetailerData?.data)
   
   const [orderType,  setOrderType] = useState("All");
   const [selectedTab, setSelectedTab] = useState('All');
   const tabNames = ['All', 'Delivered', 'Exchanged', 'Exchange Initiated', 'Returned', 'Return Initiated', 'Cancelled'];
   const handleTabClick = (tab) => {
     setSelectedTab(tab);
     setOrderType(tab);
     switch (tab) {
           case 'All':
            setProductStatus('Delivered,Exchanged,Exchange Initiated,Returned,Return Initiated,Cancelled');
            break;
           case 'Delivered':
            setProductStatus('Delivered');
            break;
           case 'Exchanged':
            setProductStatus('Exchanged');
            break;
           case 'Exchange Initiated':
            setProductStatus('Exchange Initiated');
            break;
           case 'Returned':
            setProductStatus('Returned');
            break;
            case 'Return Initiated':
            setProductStatus('Return Initiated');
            break
            case 'Cancelled':
            setProductStatus('Cancelled');
            break;
           default:
             break;
         }
   };
  
   return (
     <div className='CompletedOrders'>
       {/* navigation */}
       <div className="container">
         <div className="store-types">
         {tabNames.map(tab => (
           <div
             key={tab}
             className={orderType === tab ? 'store-type active' : 'store-type'}
             onClick={() => handleTabClick(tab)}
           >
             {tab}
           </div>
         ))}
         <div className="border"></div>
       </div>
       </div>
       <div className="orders">
      {CustomerOrderOfRetailerData?.data && [...CustomerOrderOfRetailerData.data].reverse().map(item => {
            return item.cart.map((cartItem, index) => {
              if (index === 0) {
                return <OrderCard data={{...item, cart: [cartItem]}} key={item._id} />
              } else {
                return <OrderCard data={{...item, cart: [cartItem]}} key={item._id + index} />
              }
            })
        })}
        {CustomerOrderOfRetailerData?.data && CustomerOrderOfRetailerData?.data.length == "0" && <p>No Products To Show.</p>}
        {isLoading && <p>Loading...</p>}
      </div>
     </div>
   )
}

export default CompletedOrders


export const OrderCard = (props) => {
     //login
     const [user, setUser] = useState(getUser());
     const navigate = useNavigate();
     useEffect(()=>{
       if(!user){
         navigate('/login')
       }
     },[user])
     //login end

  const accordianBodyRef = useRef(null);
  const handleCardClick = ()=>{
    if (accordianBodyRef.current) {
      const computedStyle = window.getComputedStyle(accordianBodyRef.current);  
      if (computedStyle.maxHeight === "0px") {
        accordianBodyRef.current.style.maxHeight = "unset";
      } else {
        accordianBodyRef.current.style.maxHeight = "0px";
      }
    }
  }
  const placedOrderDate = new Date(props.data.createdAt).toLocaleDateString();
  const [indicatorColor, setIndicatorColor] = useState(null);
  const [indicatorLabel, setIndicatorLabel] = useState(null)
  useEffect(()=>{
    switch(props.data?.cart[0].orderStatus){
      case "Placed": setIndicatorLabel("Order placed"); setIndicatorColor("#0d0d80"); break;
      case "In Making": setIndicatorLabel("In making"); setIndicatorColor("#ffc700"); break;
      case "Prepared": setIndicatorLabel("Prepared"); setIndicatorColor("#26a823"); break;
      case "Delivered": setIndicatorLabel("Order delivered"); setIndicatorColor("#26a823"); break;
      case "Exchanged": setIndicatorLabel("Exchanged"); setIndicatorColor("#ac772b"); break;
      case "Exchange Initiated": setIndicatorLabel("Exchange initiated"); setIndicatorColor("#ac772b"); break;
      case "Returned": setIndicatorLabel("Returned"); setIndicatorColor("#ac772b"); break;
      case "Return Initiated": setIndicatorLabel("Return initiated"); setIndicatorColor("#ac772b"); break;
      case "Cancelled": setIndicatorLabel("Cancelled"); setIndicatorColor("#E10E34"); break;
    }
  },[props.data?.cart[0].orderStatus])

  const handleReturnClick = (actionType) => {
    navigate('return', {state: {order: props.data, actionType: actionType}});
  }
  const handleStatusChange = (status) => {
    const body = { 
      product_id: props.data.cart[0].product_id ,
      productStatus: status
    }

    switch(status){
      case "Cancelled": cancelOrder(props.data._id, body); break;
      case "Delivered": markDelivered(props.data._id, body); break;
    }

  }
  const [updateCustomerOrderStatusFromRetailer, { isLoading }
  ] = useUpdateCustomerOrderStatusFromRetailerMutation();
  const cancelOrder = (order_id,body) => {  
    const confirmed = window.confirm('Are you sure you want to cancel this order?');
    if (confirmed) {
      updateCustomerOrderStatusFromRetailer({order_id:order_id,body:body,bearerToken:user}).then((res)=>{
        console.log(res)
        if(res.data.message){
          toast.success("Order Moved to Completed Orders",{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true, 
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }
        if(res.data.error){
          toast.error(res.data.error,{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true, 
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }
      })
      console.log('Order canceled');
    } else {
      console.log('Order cancellation canceled');
    }
  }
  const markDelivered = (order_id, body) => {
    const confirmed = window.confirm('Are you sure you want to mark this order as delivered?');
    if (confirmed) {
      updateCustomerOrderStatusFromRetailer({order_id:order_id,body:body,bearerToken:user}).then((res)=>{
        console.log(res)
        if(res.data.message){
          toast.success("Order Moved to Completed Orders",{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true, 
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }
        if(res.data.error){
          toast.error(res.data.error,{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true, 
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }
      })
      console.log('Order marked as delivered');
    } else {
      console.log('Order delivery canceled');
    }
  }

  const [showCancelButton, setShowCancelButton] = useState(false);
  const orderTimestamp = props.data.createdAt;
  useEffect(() => {
    const checkCancelButtonVisibility = () => {
      const orderTime = new Date(orderTimestamp);
      const currentTime = new Date();
      const timeDifference = currentTime - orderTime; // Difference in milliseconds

      const oneHour = 60 * 60 * 1000; // One hour in milliseconds

      if (timeDifference <= oneHour) {
        setShowCancelButton(true);
      } else {
        setShowCancelButton(false);
      }
    };

    // Check the button visibility on component mount
    checkCancelButtonVisibility();

    // Optionally, set an interval to check periodically
    const intervalId = setInterval(checkCancelButtonVisibility, 1000); // Check every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [orderTimestamp]);
  console.log(props.data?.shippingOption)

  //return and exchange buttton should be visible only until 10 days of order placed
  const [showReturnExchangeButton, setShowReturnExchangeButton] = useState(false);
  const orderUpdatedTimestamp = props.data.updatedAt;
  useEffect(() => {
    const checkReturnExchangeButtonVisibility = () => {
      const orderTime = new Date(orderUpdatedTimestamp);
      const currentTime = new Date();
      const timeDifference = currentTime - orderTime; // Difference in milliseconds

      const tenDays = 10 * 24 * 60 * 60 * 1000; // 10 days in milliseconds

      if (timeDifference <= tenDays) {
        setShowReturnExchangeButton(true);
      } else {
        setShowReturnExchangeButton(false);
      }
    };

    // Check the button visibility on component mount
    checkReturnExchangeButtonVisibility();

    // Optionally, set an interval to check periodically
    const intervalId = setInterval(checkReturnExchangeButtonVisibility, 1000); // Check every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }
  , [orderUpdatedTimestamp]);

  return (
    <div className='OrderCard'>
      <div className="card-main" onClick={handleCardClick}>
        <div className="image-box">
          {/* <img src={props.data.cart?.[0].image?.[0]} alt={props.data.cart?.[0].title?.en} /> */}
          <img src={props.data.cart?.[0]?.variant?.options ? props.data.cart?.[0]?.variant?.image.replace("upload/", "upload/if_w_gt_200/w_200/if_end/") : props.data.cart?.[0]?.images?.[0].replace("upload/", "upload/if_w_gt_200/w_200/if_end/")} alt={props.data.cart?.[0].title?.en} />
          </div>
        <div className="order-details">
          <div className="order-info">
            <div>Order No: {props.data?.invoice}</div>
            <div>SKU : {props.data.cart?.[0]?.sku}</div>
          </div>
          <div className="order-status ">
            <div className="indicator" style={{background:indicatorColor}}></div>
            <div className="label">{props.data?.cart[0].orderStatus}</div>
          </div>
        </div>
        <div className="customer-details text order-details">
          <div className="order-info">
            <div> Customer name: {props.data?.user_info?.firstname}</div>
            <div>Phone No : {props.data?.user_info?.contact}</div>
          </div>
          <div>Order placed: {placedOrderDate}</div>
        </div>
        <div className="payment-details text order-details">
          <div className="order-info">
            <div>Order total {props.data.total}</div>
            <div>Mode of payment: {props.data.paymentMethod}</div>
          </div>
          <div>Shipping Option: {props.data?.shippingOption == "netre" ? "Home Delievery" : props.data?.shippingOption}</div>
        </div>
        <div className="details-mobile">
          <div> Customer name:</div>
          <div>{props.data?.user_info?.firstname}</div>
          <div>Email : </div>
          <div>{props.data?.user_info?.email}</div>
          <div>order total</div>
          <div>&#8377;{props.data?.total}</div>
          <div className='payment-info'>Mode of payment:</div>
          <div className='payment-info'>{props.data?.paymentMethod}</div>
          <div>Order placed: </div>
          <div>{placedOrderDate}</div>
          <div>Shipping Option: </div>
          <div>{props.data?.shippingOption}</div>
          {/* <div>Order Received: </div>
          <div>1st June, 2024</div> */}
        </div>
      </div>
      <div className="card-accordian-body" ref={accordianBodyRef}>
        <div className="content">
          <div className="cards">
            <div className='container'>

            {props.data.cart?.map((item, index) => (
              <>    
              <div className="frame-card" key={index}>
                <div className="label mont-bold">{item.exchangedItem ? "Old Frame Details" : "Frame Details"}</div>
                <img src={item?.variant?.options ? item?.variant?.image.replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : item?.images?.[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/")} alt={item.title?.en} />
                <div className="text">SKU : {item?.sku}</div>
                {item?.variant?.options && Object.keys(item.variant.options).map(key => (
                    <div key={key} className='options-parent'>
                      {Object.keys(item.variant.options[key]).map((key2) => ( 
                        <div key={key2} className='options'>
                          <span className='stock'>{key2}:&nbsp;</span>
                          <span className='stock'>{item.variant.options[key][key2]}</span>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>

              {props.data.isLens && <div className="frame-card">
                <div className="label mont-bold">Lens Details</div>
                <img src="https://w7.pngwing.com/pngs/463/852/png-transparent-contact-lens-invisible-contact-lenses-spectacle-lenses.png" alt="" />
                <div className="text">crizal lens 300789</div>
              </div>}
              </>
            ))}
            {props.data.cart?.map((item, index) => (
              <>    
              {item.exchangedItem && item.exchangedItem.map((exchangedItem, index) => (
                <div className="frame-card" key={index}>
                  <div className="label mont-bold">New Frame Details</div>
                  <img src={exchangedItem?.variant?.options ? exchangedItem?.variant?.image.replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : exchangedItem?.images?.[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/")} alt={exchangedItem.title?.en} />
                  <div className="text">SKU : {exchangedItem?.sku}</div>
                  {exchangedItem?.variant?.options && Object.keys(exchangedItem.variant.options).map(key => (
                      <div key={key} className='options-parent'>
                        {Object.keys(exchangedItem.variant.options[key]).map((key2) => ( 
                          <div key={key2} className='options'>
                            <span className='stock'>{key2}:&nbsp;</span>
                            <span className='stock'>{exchangedItem.variant.options[key][key2]}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              ))}
              </>
            ))}
            </div>
            <div className="price-breakdown">
              <div className="label mont-bold">Price Breakdown</div>
              <div className="prices">
                <div className="att-label">Frame Price</div>
                <div className="price">&#8377;{props.data.subTotal}</div>
              </div>
              
              <div className="prices">
                <div className="att-label">Discount</div>
                <div className="price">&#8377;{props.data.discount}</div>
              </div>
              <div className="prices total">
                <div className="att-label">Total Price</div>
                <div className="price">&#8377;{props.data.total}</div>
              </div>
              <div className="text2 payment">Mode of payment: {props.data.paymentMethod}</div>
              <div className="text2">Amount Paid: &#8377;{props.data.total}</div>
              <div className="text2">Amount Pending: &#8377;0</div>
            </div>
            {props.data?.cart[0].orderStatus === 'Delivered' && showReturnExchangeButton && <div className="return-exchange-btn">
              {/* <div className="return">RETURN</div> */}
              <div className="return" onClick={()=>{handleReturnClick('return')}}>RETURN</div>
              <div className="exchange" onClick={()=>{handleReturnClick('exchange')}}>EXCHANGE</div>
            </div>}
            {props.data?.cart[0].orderStatus === 'Placed' &&   
            <div className="return-exchange-btn">
               {showCancelButton && <div className="return" onClick={()=>{handleStatusChange('Cancelled')}}>Cancel</div>}
              <div className="exchange" onClick={()=>{handleStatusChange('Delivered')}}>Mark Delivered</div>
            </div>
            }
            <div className="vertical-bar"></div>
          </div>
        </div>  
      </div>
    </div>
  )
}
