import React, { useEffect, useState } from 'react'
import './RetailerOrderHistory.scss'
import { getUser } from '../../home/Home';
import { useNavigate } from 'react-router-dom';
import { useGetRetailerOrdersQuery, useUpdateRetailerOrderStatusMutation } from '../../features/product/productSlice';
function RetailerOrderHistory() {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(()=>{
    if(!user){
      navigate('/login')
    }
  },[user])
  //login end
  
  const [productStatus, setProductStatus] = useState('Pending,Placed,Delivered,Returned,Return Initiated,Exchanged,Exchange Initiated,Cancelled');
  const {
    data: retailerOrderData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRetailerOrdersQuery({productStatus:productStatus, bearerToken:user})
  if(isError && error.status === 401){
    navigate('/login');
  }
  const [orderType,  setOrderType] = useState("All");
  const [selectedTab, setSelectedTab] = useState('All');
  const tabNames = ["All", "Pending" , "Placed" , "Delivered" , "Returned", "Return Initiated", "Exchanged", "Exchange Initiated", "Cancelled"];
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setOrderType(tab);
    switch (tab) {
      case 'All':
        setProductStatus('Pending,Placed,Delivered,Returned,Return Initiated,Exchanged,Exchange Initiated,Cancelled');
        break;
      case 'Pending':
        setProductStatus('Pending');
        break;
      case 'Placed':
        setProductStatus('Placed');
        break;
      case 'Delivered':
        setProductStatus('Delivered');
        break;
      case 'Returned':
        setProductStatus('Returned');
        break;
      case 'Return Initiated':
        setProductStatus('Return Initiated');
        break;
      case 'Exchanged':
        setProductStatus('Exchanged');
        break;
      case 'Exchange Initiated':
        setProductStatus('Exchange Initiated');
        break;
      case 'Cancelled':
        setProductStatus('Cancelled');
        break;
      default:
        break;
    }
  };

  const [singleProduct, setSingleProduct] = useState(null);
  const [frameTypes, setFrameTypes] = useState('Old Frames');
  useEffect(() => {
    if(singleProduct){
      setFrameTypes('Old Frames');
    }
  },[singleProduct]) 
  const [updateRetailerOrderStatus, {isLoadingCancelOrder, isSuccessCancelOrder, isErrorCancelOrder, errorCancelOrder}] = useUpdateRetailerOrderStatusMutation();
  if(isErrorCancelOrder){
    console.log(errorCancelOrder)
  }
  const handleCancelOrderClick = () => {
    console.log("cancel order",singleProduct.orderNumber)
    const body = {
      orderNumber: singleProduct.orderNumber,
      status: "Cancelled"
    }
        // Ask for confirmation before canceling the order
        const confirmed = window.confirm('Are you sure you want to cancel this order?');
    
        // If user confirms, proceed with cancelation
        if (confirmed) {
          updateRetailerOrderStatus({body:body,bearerToken:user})
          console.log('Order canceled');
        } else {
          console.log('Order cancellation canceled');
        }
  }

  return (
    <div className='CompletedOrders RetailerOrders'>
      {singleProduct && <div className="Single-order-history">
      <div className='add-frame-text' onClick={()=>{setSingleProduct(null)}}><span><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg></span> <span className='mont-regular'>Order History</span> </div>
        <h3 className="invoice-no"><span>Order No.</span> <span>{singleProduct?.orderNumber}</span></h3>
        <div className="info">
          {/* <div className="item status"><span>Status:</span> <span>{singleProduct?.status}</span></div> */}
          <div className="item total"><span>Total Frames:</span> <span>{singleProduct?.totalFrames}</span></div>
          <div className="item label"> <span>Bill Uploaded</span> <span>{singleProduct?.billsUploaded ? "yes" : "No"}</span></div>
          {/* <div className="item cancel"> {singleProduct?.status === "Placed" && <div onClick={handleCancelOrderClick}>Cancel</div>} </div> */}
        </div>
        {singleProduct?.exchangedProducts && singleProduct?.exchangedProducts.length > 0 
        &&
        <div className="container">
            <div className="store-types">
              <div className={frameTypes == "Old Frames" ? 'store-type active' :'store-type'} onClick={()=>{setFrameTypes('Old Frames');}}>Old Frames</div>
              <div className={frameTypes == "New Frames" ? 'store-type active' :'store-type'} onClick={()=>{setFrameTypes('New Frames'); }}>New Frames</div>
              <div className="border w-100"></div>
            </div>
        </div>
        }

        {singleProduct?.exchangedProductsOld && singleProduct?.exchangedProductsOld.length > 0 
            && 
            <div className="container">
                <div className="store-types">
                  <div className={frameTypes == "New Frames" ? 'store-type active' :'store-type'} onClick={()=>{setFrameTypes('New Frames'); }}>New Frames</div>
                  <div className={frameTypes == "Old Frames" ? 'store-type active' :'store-type'} onClick={()=>{setFrameTypes('Old Frames');}}>Old Frames</div>
                  <div className="border w-100"></div>
                </div>
            </div>
        }
        {(singleProduct?.exchangedProductsOld.length < 1 && singleProduct?.exchangedProducts.length < 1 ) && <h3>Frame List</h3>}

        {frameTypes == "Old Frames" && (singleProduct?.exchangedProductsOld.length < 1) && <div className="frame-grid">
            {singleProduct.products.map(product => (
            <div className="frame-card" key={product._id}>
              <div className="img-box">
                <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : ""} alt={product.name} />
              </div>
              <div className='product'>
                <div className="">
                <div className='product-name'>{product.name}</div>
                <div className='product-sku'>sku:&nbsp;{product.sku}</div>
                </div>
                <div className='d-flex j-sb mt-10'>
                  <div className="status">{product.orderStatus}</div>
                  <div className='product-return-quantity'><div className="return"></div><div className="quantity">{product.quantity}</div></div>
                </div>
              </div>
            </div>
            ))}
        </div>}
        {frameTypes == "New Frames" && (singleProduct?.exchangedProductsOld.length > 0) && <div className="frame-grid">
            {singleProduct.products.map(product => (
            <div className="frame-card" key={product._id}>
              <div className="img-box">
                <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : ""} alt={product.name} />
              </div>
              <div className='product'>
                <div className="">
                <div className='product-name'>{product.name}</div>
                <div className='product-sku'>sku:&nbsp;{product.sku}</div>
                </div>
                <div className='d-flex j-sb mt-10'>
                  <div className="status">{product.orderStatus}</div>
                  <div className='product-return-quantity'><div className="return"></div><div className="quantity">{product.quantity}</div></div>
                </div>
              </div>
            </div>
            ))}
        </div>}
        {frameTypes == "New Frames" && singleProduct?.exchangedProducts && singleProduct?.exchangedProducts.length > 0 && <div className="frame-grid">
            {singleProduct.exchangedProducts.map(product => (
            <div className="frame-card" key={product._id}>
              <div className="img-box">
                <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : ""} alt={product.name} />
              </div>
              <div className='product'>
                <div className="">
                <div className='product-name'>{product.name}</div>
                <div className='product-sku'>sku:&nbsp;{product.sku}</div>
                </div>
                <div className='d-flex j-sb mt-10'>
                  <div className="status">{product.orderStatus}</div>
                  <div className='product-return-quantity'><div className="return"></div><div className="quantity">{product.quantity}</div></div>
                </div>
              </div>
            </div>
            ))}
        </div>}

        {frameTypes == "Old Frames" && singleProduct?.exchangedProductsOld && singleProduct?.exchangedProductsOld.length > 0 && <div className="frame-grid">
            {singleProduct.exchangedProductsOld.map(product => (
            <div className="frame-card" key={product._id}>
              <div className="img-box">
                <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_300/w_300/if_end/") : ""} alt={product.name} />
              </div>
              <div className='product'>
                <div className="">
                <div className='product-name'>{product.name}</div>
                <div className='product-sku'>sku:&nbsp;{product.sku}</div>
                </div><div className='d-flex j-sb mt-10'>
                  <div className="status">{product.orderStatus}</div>
                  <div className='product-return-quantity'><div className="return"></div><div className="quantity">{product.quantity}</div></div>
                </div>
              </div>
            </div>
            ))}
        </div>}

      </div>}
      
      {/* navigation */}
      
      {!singleProduct && <>
      <div className="container">
        <div className="store-types">
        {tabNames.map(tab => (
          <div
            key={tab}
            className={orderType === tab ? 'store-type active' : 'store-type'}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
        <div className="border"></div>
      </div>
      </div>
      <div className="orders">
      {retailerOrderData?.data && [...retailerOrderData.data.orders].reverse().map(item => (
            <OrderHistoryCard data={item} key={item._id} setSingleProduct={setSingleProduct} />
        ))}
        {retailerOrderData?.data.orders.length == 0 && <div className="no-orders">No Orders To Show</div>}
      </div>
      </>}
    </div>
  )
}

export default RetailerOrderHistory

const OrderHistoryCard = ({data, setSingleProduct}) => {
    //login
    const [user, setUser] = useState(getUser());
    const navigate = useNavigate();
    useEffect(()=>{
      if(!user){
        navigate('/login')
      }
    },[user])
    //login end
  
  const [totalFrames, setTotalFrames] = useState(0);
  const findTotalFrames = (cart) => {
    let totalFrames = 0;
    cart.forEach(item => {
      totalFrames += item.quantity;
    });
    return totalFrames;
  }
  useEffect(() => {
    setTotalFrames(findTotalFrames(data.products));
  }, [data.products]);

  const [updateRetailerOrderStatus, {isLoading, isSuccess, isError, error}] = useUpdateRetailerOrderStatusMutation();
  const handleCancelOrderClick = () => {
    console.log("cancel order",data.orderNumber)
    const body = {
      orderNumber: data.orderNumber,
      status: "Cancelled"
    }
        // Ask for confirmation before canceling the order
        const confirmed = window.confirm('Are you sure you want to cancel this order?');
    
        // If user confirms, proceed with cancelation
        if (confirmed) {
          updateRetailerOrderStatus({body:body,bearerToken:user})
          console.log('Order canceled');
        } else {
          console.log('Order cancellation canceled');
        }
  }
  const handleViewOrderClick = () => {
    console.log(data)
    setSingleProduct({...data, totalFrames: totalFrames});
  }
  return (
    <div className='order-history-card' onClick={handleViewOrderClick}>
      <div className='order-details'>
        <div className='order-details-item order-id'>
            <div className="label">Order No.</div>
            <div className="data mont-bold">{data?.orderNumber}</div>
        </div>
        <div className='order-details-item order-frames'> 
          <div className="label"> Total Frames </div>
          <div className="data">{totalFrames}</div>
        </div>
        <div className='order-details-item order-status'>
          <div className="label"> Bill Uploaded </div>
          <div className="data">{data.billsUploaded ? "yes" : "No"}</div>
        </div>
        {/* {data?.status === "Placed" && <div className='order-details-item order-cancel'onClick={handleCancelOrderClick} >cancel order</div>} */}
      </div>
      <div className='order-products'>
      </div>
    </div>
  )
}