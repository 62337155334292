import React, { useEffect, useState } from "react";
import { ContactLast, Logo } from "../signup/Signup";
import { Header } from "../home/Home";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import axios from "axios";
import "./resetPassword.scss";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  // redux state
  const { error } = useSelector((state) => state.user);

  const handleLoginEvent = async (values) => {
    setLoading(true);

    try {
      const response = await axios(
        process.env.REACT_APP_BASE_URL + `retailer/reset-password`,
        {
          method: "PUT",
          data: {
            token: params?.token,
            newPassword: values?.confirmPassword,
          },
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      navigate("/login");
      toast.success(response?.data?.message);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const forgotFormik = useFormik({
    initialValues: {
      password: "",
    },
    onSubmit: async function (values) {
      handleLoginEvent(values);
    },
    validate: (values) => {
      let errors = {};
      if (!values?.password) {
        errors.password = "New password is Required!";
      } else if (!values?.confirmPassword) {
        errors.confirmPassword = "Confirm password is Required!";
      } else if (values?.password !== values?.confirmPassword) {
        errors.confirmPassword = "New and Confirm password are not same!";
      }
      return errors;
    },
  });

  return (
    <form className="reset_password" onSubmit={(e) => e.preventDefault()}>
      <Logo />
      <Header />
      <div className="text-1 font-sabon">Reset Password</div>
      <div className="text-2">Please enter your new password</div>

      <div className="fields">
        <div className="field">
          <input
            type="password"
            placeholder="New Password"
            name="password"
            onChange={forgotFormik.handleChange}
            onBlur={forgotFormik.handleBlur}
            value={forgotFormik.values.password}
          />
          {forgotFormik.errors.password && forgotFormik.touched.password ? (
            <div className="error-msg">{forgotFormik.errors.password}</div>
          ) : null}
        </div>
        <div className="field">
          <input
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            onChange={forgotFormik.handleChange}
            onBlur={forgotFormik.handleBlur}
            value={forgotFormik.values.confirmPassword}
          />
          {forgotFormik.errors.confirmPassword &&
          forgotFormik.touched.confirmPassword ? (
            <div className="error-msg">
              {forgotFormik.errors.confirmPassword}
            </div>
          ) : null}
        </div>
      </div>
      <div className="create-acc-btn">
        <button
          disabled={loading}
          onClick={forgotFormik.handleSubmit}
          className="btn"
          type="submit"
        >
          {loading ? "Loading..." : "SAVE"}
        </button>
      </div>
      {/* <div className="text-5"><div className='box'><Link to="/account" className='btn'>Forgot your password ?</Link></div></div> */}
      <div className="or-mobile">
        <div className="or">OR</div>
        <div className="hr-line"></div>
      </div>
      <Link to="/signup" className="or-text">
        Sign Up
      </Link>
      <ContactLast />
    </form>
  );
};

export default ResetPassword;
