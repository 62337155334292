import { createSlice } from '@reduxjs/toolkit';

const homeInquirySlice = createSlice({
  name: 'homeInquiry',
  initialState: {
    products: [],
    customer: null,
  },
  reducers: {
    addHomeInquiryProducts: (state, action) => {
        state.products = action.payload;
    },
    removeProductFromHomeInquiry: (state, action) => {
      state.products = state.products.filter((product) => product.product_id   !== action.payload);
    },
    setHomeInquiryCustomer: (state, action) => {
      state.customer = action.payload;
    },
  },
});

export const {
  addHomeInquiryProducts, 
  removeProductFromHomeInquiry,
  setHomeInquiryCustomer} = homeInquirySlice.actions;
export default homeInquirySlice.reducer;