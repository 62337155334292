import React from 'react'
import '../profile/Profile.scss'
import { Link } from 'react-router-dom'
function Marketing() {
  return (
    <div className='profile'>
      <h3>Marketing Collaterals</h3>
      {/* grid of 3 column square ratio each item a button at bottom half and a picture filled in item */}
      <div className='profile-grid'>
        <Link to="/" className='profile-item'>
            <img src="https://media.self.com/photos/5dd44cf5d0525b0009f5edf6/4:3/w_2560%2Cc_limit/AdobeStock_208000726.jpeg" alt="" />
            <button>Request Order</button>
        </Link>
        <Link to="/" className='profile-item'>
            <img src="https://media.allure.com/photos/641c7d3eceb2146b4f84c08b/1:1/w_2000,h_2000,c_limit/3-22-where-to-buy-glasses.jpg" alt="" />
            <button>Request Order</button>
        </Link>
        <Link to="/" className='profile-item'>
            <img src="https://images.squarespace-cdn.com/content/v1/60dcb717d1969c396e25dc44/8cfc44bd-288f-4c47-965b-3b3446b44125/rainbow-frames_0128-home.jpg" alt="" />
            <button>Request Order</button>
        </Link>
      

      </div>
    </div>
  )
}

export default Marketing