import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import {  useGenerateOTPforCustomerSignupMutation, useGetCouponDetailsFromCodeQuery, useGetCustomerByMobileQuery, useGetNetreCareDataQuery, useGetProductDataBySkuQuery, useGetRetailerInfoQuery, useRegisterCustomerFromRetailerMutation } from '../../features/product/productSlice';
import { getUser } from '../../home/Home';
import "./NewOrder.scss"
import { useAddCustomerOrderFromRetailerMutation } from '../../features/order/cutomerOrderSlice';
import { Field, Formik, Form, useFormik, FormikConsumer } from 'formik';
import axios from 'axios';


function NewOrder(props) {
    //login
    const [user, setUser] = useState(getUser());
    const navigate = useNavigate();
    useEffect(()=>{
      if(!user){
        navigate('/login')
      }
    },[user])
    //login end

    const goBack = () => {
      navigate(-1);
    };


    const { data: retailerData, isLoading: isLoadingRetailer, isSuccess: isSuccessRetailer, isError: isErrorRetailer, error: errorRetailer } = useGetRetailerInfoQuery({ bearerToken:user });

    const [products, setProducts] = useState([]);
    const addProductToCart = (product) => {
      // max 4 products can be added to cart
      if(products.length === 4){
        toast.error("Max 4 Products Can Be Added Per Order",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          }
        )
        return;
      }
      const quantity = product.quantity;
          // before adding to cart check if the quantity is available
          if(quantity < 1){
            toast.error("Product is out of stock",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              }
            )
            return;
          }
          // count of the same product in products and not add more than quanity
          const count = products.filter((item) => item.sku === product.sku).length;
          if(count < quantity){
            setProducts([...products, product])
            setSearchResults([]);
            formik.resetForm()
          }else{
            toast.error(`Only ${quantity} frames available`,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              }
            )
            formik.resetForm()
          }
        }
    const handleRemoveProductFromCart = (product) => {
      const filteredProducts = products.filter((item) => item !== product);
      setProducts(filteredProducts);
    }


    // sku 
    const errors = {}    
    const formik = useFormik({
      initialValues: {
        sku: '',
      },
      onSubmit: async function (values) {
        
        try{
          const response = await axios( 
            process.env.REACT_APP_BASE_URL + `/retailer/products/sku/${values.sku}`, 
            { method: 'GET', data: formik.values, 
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              "Authorization": `Bearer ${user}`
          }, }, )
          // console.log(response)
          addProductToCart(response.data.data.product);
        }
        catch(err){
          console.log(err)
          toast.error(err.response.data.message,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              }
          )
        }
      },
      onChange: function (values) {
        console.log(values)
      },
      validate: (values) => {
        if (!values.sku) {
          errors.sku = 'Required'
          setSearching(false)
          setSearchResults([])
        }
        return errors
      },
    })

    // debounce search of sku suggestion
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const searchRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(-1);
    useEffect(() => {
      if (search.length > 0) {
        setSearching(true);
        const timer = setTimeout(() => {
          searchRef.current = axios(
            process.env.REACT_APP_BASE_URL + `/retailer/products/search/${search}`,
            {
              method: "GET",
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                Authorization: `Bearer ${user}`,
              },
            }
          )
            .then((res) => {
              if(res.data.data.productsInfo){
                setSearchResults(res.data.data.productsInfo);
                setSearching(false);
              }
            })
            .catch((err) => {
              console.log(err);
              setSearching(false);
            });
        }, 800);
        return () => clearTimeout(timer);
      }
    }, [search]);

    const handleSearchKeyDown = (e) => {
      if(selectedItem < searchResults.length ){
        if (e.key === "ArrowDown") {
          setSelectedItem((prev) => (prev < searchResults.length - 1 ? prev + 1 : prev));
        }
        if (e.key === "ArrowUp") {
          setSelectedItem((prev) => (prev > 0 ? prev - 1 : prev));
        }
        if (e.key === "Enter") {
          if (selectedItem > -1) {
            addProductToCart(searchResults[selectedItem]);
          }
        }
      }else{
        setSelectedItem(-1);
      }
    }


    const [checkoutType, setCheckoutType] = useState("guest");
    const [shippingOption, setShippingOption] = useState("store");
    const [isPickupFromStore, setIsPickupFromStore] = useState(true);

    //customer details 
    const [customer, setCustomer] = useState("")
    // find customer by mobile and if not found then generate otp
    //temp address id
    const [addressId, setAddressId] = useState("");
    const customerFormik = useFormik({
      initialValues: {
        phone: "",
      },
      onSubmit: async function (values) {
        try{
          const response = await axios( 
            process.env.REACT_APP_BASE_URL + `/customer/mobile/${values.phone}`, 
            { method: 'GET', data: customerFormik.values, 
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
          }, }, )
          console.log(response.data.customer)
          setCustomer(response.data.customer)
          registerFormik.setFieldValue("firstname", response.data.customer.firstname);
          setTimeout(() => registerFormik.setFieldTouched("firstname", true));
          registerFormik.setFieldValue("lastname", response.data.customer.lastname);
          setTimeout(() => registerFormik.setFieldTouched("lastname", true));
          registerFormik.setFieldValue("email", response.data.customer.email);
          setTimeout(() => registerFormik.setFieldTouched("email", true));

          if(response.data.customer.addresses.length > 0){
            adressFormik.setFieldValue("address1", response.data.customer.addresses[0].address1);
            setTimeout(() => adressFormik.setFieldTouched("address1", true));
            adressFormik.setFieldValue("address2", response.data.customer.addresses[0].address2);
            setTimeout(() => adressFormik.setFieldTouched("address2", true));
            adressFormik.setFieldValue("city", response.data.customer.addresses[0].city);
            setTimeout(() => adressFormik.setFieldTouched("city", true));
            adressFormik.setFieldValue("zipCode", response.data.customer.addresses[0].zipCode);
            setTimeout(() => adressFormik.setFieldTouched("zipCode", true));
            setAddressId(response.data.customer.addresses[0]._id);
          }else{
            setAddressId("");
          }

          setShowOTP(false)
          // setShippingOption("netre")
        }
        catch(err){
          console.log(err)
          toast.error(err.response.data.message,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              }
          )
          // setShowOTP(true)
          setCustomer("")
          // customerFormik.resetForm()
        }
      },
      validate: (values) => {
        if (!values.phone) {
          errors.phone = 'Required';
        } else if (!/^[0-9]{10}$/.test(values.phone)) {
          errors.phone = 'Invalid mobile number';
        }
        return errors
      },
    })
    

    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOtp] = useState("");


    const registerFormik = useFormik({
      initialValues: {
        firstname: "",
        lastname: "",
        email: "",
        otp:""
      },
      onSubmit: async function (values) {
        console.log(values)
        try{
          const response = await axios( 
            process.env.REACT_APP_BASE_URL + `/customer/register`, 
            { method: 'POST', data: {...registerFormik.values , phone: customerFormik.values.phone,}, 
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              "Authorization": `Bearer ${user}`
          }, }, )
          console.log(response)
          setCustomer(response.data.customer)
          setShowOTP(false)
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });

        }
        catch(err){
          console.log(err)
          toast.error(err.response.data.message,{
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              }
          )
          setCustomer("")
        }
        console.log(values.firstname, values.lastname, values.email, customerFormik.values.phone, values.otp)
      },
      validate: (values) => {
        // if (!values.phone) {
        //   errors.phone = 'Required'
        // }

        if (!values.firstname) {
          errors.firstname = 'Name Required*'
        }
        // else if(
        //   !/^[ a-zA-Z\-\']+$/i.test(values.firstname)
        // ){
        //   errors.firstname = 'Invalid Name*'
        // }

        if (!values.lastname) {
          errors.lastname = 'Name Required*'
        }
        // else if(
        //   !/^[ a-zA-Z\-\']+$/i.test(values.lastname)
        // ){
        //   errors.lastname = 'Invalid Name*'
        // }
  
    
        // if (!values.email) {
        //   errors.email = 'Email Required*'
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.email = 'Invalid email address*'
        // }

        if (!values.otp) {
          errors.otp = 'Required';
        }

        return errors
      },
    })

    const [generateOTPforCustomerSignup,{
      data:otpData,
      isLoading:isLoadingOTP,
      isSuccess:isSuccessOTP,
      isError:isErrorOTP,
      error:errorOTP
    }] = useGenerateOTPforCustomerSignupMutation();
    const sendOTP = () => {
      setShowOTP(true);
      const body = {
        "phone": customerFormik.values.phone
      };
      generateOTPforCustomerSignup({body, bearerToken:user}).then((res)=>{
        //toast success
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
          console.log(res.data)
          setOtp(res.data.otp)
        });
    }

    const adressFormik = useFormik({
      initialValues: {
        address1: "",
        address2: "",
        city: "",
        zipCode: "",
      },
      onSubmit: async function (values) {
        console.log(values)
      },
      validate: (values) => {
        if (!values.address1) {
          errors.address1 = 'Required'
        }
        if (!values.address2) {
          errors.address2 = 'Required'
        }
        if (!values.city) {
          errors.city = 'Required'
        }
        if (!values.zipCode) {
          errors.zipCode = 'Required'
        }
        return errors
      },
    })

    const handleCreateAccountCheckboxChange = (e) => {
      if (e.target.checked) {
        setShowOTP(true);
        setCheckoutType("customer");
      } else {
        setShowOTP(false);
        setCheckoutType("guest");
      }}

      // if customer is found then set checkout type to customer
      useEffect(()=>{
        if(customer){
          setCheckoutType("customer")
        }else{
          setCheckoutType("guest")
        }
      },[customer])
      
      const handlePickFromStoreChange = (e) => {
        if (e.target.checked) {
          setShippingOption("store");
          setIsPickupFromStore(true);
        } else {
          setShippingOption("netre");
          setIsPickupFromStore(false);
        }
      }

      //payemnt options array and component and state to store
  const paymentOptions = [
    {
      id: 1,
      name: "Cash"
    }
  ]
  const [paymentOption, setPaymentOption] = useState(paymentOptions[0].name)
  const PaymentOptions = (props) => {
    return (
      <div className="payment-options">
        {props.options.map((option, index) => {
          return (
             <div className="radio" key={index}>
             <input type="radio" name="payment" id={`payment-${option.name}`}  value={option.name} checked={paymentOption === option.name} onChange={()=>{setPaymentOption(option.name)}}/>
             <label htmlFor={`payment-${option.name}`}>{option.name}</label>
            </div>
          )
        })}
      </div>
    )
  }

    // total is sum of all products price
    const total =Number(products.reduce((acc, item) => acc + item?.price, 0))
  const[addCustomerOrderFromRetailer, orderStatus]=useAddCustomerOrderFromRetailerMutation() 
  useEffect(()=>{
    if(orderStatus.isSuccess){
        toast.success("Your order has been placed", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true, 
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
      navigate('/orders/current')
  }  
  },[orderStatus.isSuccess])


  const lensFormik = useFormik({
    initialValues: {
      "brandName": "",
      "lensType": "",
      "rightEyeSphere": "",
      "rightEyeCylinder": "",
      "rightEyeAxis": "",
      "leftEyeSphere": "",
      "leftEyeCylinder": "",
      "leftEyeAxis": "",
      "lensPrice": "",
      "discount": ""
    },
    onSubmit: async function (values) {
      console.log(values)
    },
    
  })
  const placeOrder = () => {
    if(products.length < 1){
      formik.errors.sku = "Required";
      toast.error("Add atleast one product", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      return;
    }
    
    if(!registerFormik.values.firstname){
      registerFormik.validateForm()
      toast.error("Name is required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      return;
    }

    if(!customerFormik.values.phone){
      customerFormik.validateForm()
      toast.error("Mobile Number is required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if(customerFormik.errors?.phone == 'Invalid mobile number'){
      toast.error("Invalid mobile number", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }



    // if(!customer._id){
    //   // customerFormik.errors.phone = "Required";
    //   toast.error("Mobile Number is required", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //     transition: Bounce,
    //     });
    //   return;
    // }
    if(!adressFormik.values.address1 && shippingOption === "netre"){
      adressFormik.errors.address1 = "Required";
      toast.error("Address 1 is required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      return;
    }
    if(!adressFormik.values.city && shippingOption === "netre"){
      adressFormik.errors.city = "Required";
      toast.error("City is required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      return;
    }
    if(!adressFormik.values.zipCode && shippingOption === "netre"){
      adressFormik.errors.zipCode = "Required";
      toast.error("Pincode is required", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      return;
    }

    const body = {
      "cart": products.map((item)=>({
        "product_id": item.product_id,
        "name": item.name,
        "title": item.name,
        "images": item.images,
        "sku": item.sku,
        "quantity": 1,
        "price": item.price,
        "isCombination": item.isCombination,
        "variants": [],
        "attributes": item.attributes,
        "orderStatus":"Placed",
        "netreCare": [],
        "prescription":[
          {
            "brandName": lensFormik.values.brandName,
            "lensType": lensFormik.values.lensType,
            "rightEye": {
                "sphere": lensFormik.values.rightEyeSphere,
                "cylinder": lensFormik.values.rightEyeCylinder,
                "axis": lensFormik.values.rightEyeAxis
            },
            "leftEye": {
                "sphere": lensFormik.values.leftEyeSphere,
                "cylinder": lensFormik.values.leftEyeCylinder,
                "axis": lensFormik.values.leftEyeAxis
            },
            "price": lensFormik.values.lensPrice,
            "discount": lensFormik.values.discount
        }
        ]
      })),
      "discount": 0,
      "user_info": {
       "firstname": customer?.firstname || registerFormik.values.firstname,
       "lastname": customer?.lastname || registerFormik.values.lastname,
        "contact": customerFormik.values.phone ,
        "email": customer?.email || registerFormik.values.email,
        "address": {
            "_id": customer?.firstname && addressId ? addressId : "",
            "firstname": registerFormik.values.firstname,
            "lastname": registerFormik.values.lastname,
            "phone":  customerFormik.values.phone,
            "address1": adressFormik.values.address1,
            "address2": adressFormik.values.address2,
            "country": "",
            "city": adressFormik.values.city,
            "zipCode": adressFormik.values.zipCode
        }
      },
      "shippingOption": shippingOption,
      "checkoutType": checkoutType,
      "couponCode":"",
      "paymentMethod": paymentOption,
      "status": "Placed",
      "subTotal": total,
      "shippingCost": 0,
      "total": total,
      customerId: customer?._id || "",
      "reatilerId": retailerData?.data && retailerData?.data._id
    }
    console.log(body)
    addCustomerOrderFromRetailer({order:body,bearerToken:user}).then
    ((res)=>{
      console.log(res)
      if(res.error){
        toast.error(res.error.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
      }
    })
  }

  // buy now from product page 
  const [sku, setSku] = useState(null);
  const order = useSelector((state) => state.order.orderId);
  useEffect(() => {
    if (order) {
      if(order.sku){
      formik.setFieldValue('sku', order.sku);
      setTimeout(() => formik.setFieldTouched("sku", true), 1000);
      setSku(order.sku);
      formik.handleSubmit();
      }
    }
  }, [order, order?.sku]);

  useEffect(() => {
    if (sku) {
      formik.setFieldValue('sku', sku);
      setTimeout(() => formik.setFieldTouched("sku", true), 1000);
      setSku(null);
      formik.handleSubmit();
    }
  }
  , [sku]);


  return (
    <div className='order'>
      <div className="navigation" onClick={()=>{goBack()}}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg>
          <div className="label mont-bold" >Place Order</div>
      </div>

      <div className='form-main'>
        <div className="sku">
        <input 
        type="text" 
        name="sku"
        id='sku' 
        placeholder="SKU"
        onChange={
          (e)=>{
            formik.handleChange(e);
            setSearch(e.target.value)
          }
        }
        value={formik.values.sku}
        autoComplete='off'
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Tab') {
            formik.handleSubmit()
          }
          handleSearchKeyDown(e)
        }
        }
        />
        <div className="add-sku" onClick={formik.handleSubmit}>Add</div>
          {formik.errors.sku && products.length < 1 ? <div className="error">{formik.errors.sku}</div> : null}
          <div className="sku-search">
            {/* {searching && <div className="searching">Searching...</div>} */}
            {searchResults.length > 0 && !searching && <div className="search-results">
              {searchResults.map((item, index) => (
                <div className={selectedItem === index ? "result active": "result" } key={index} onClick={() => {
                  addProductToCart(item);
                }}>
                  <div className='img-box'>
                    <img src={item.images ? item.images[0].replace("upload/", "upload/if_w_gt_150/w_150/if_end/") : ""} />
                  </div>
                  <div className="sku-info">SKU:&nbsp;{item?.sku}</div>
                </div>
              ))}
            </div>}
          </div>
        </div>

        <div className="customer-details">
          <div className="title">Customer details</div>
          <input type="text" 
          placeholder='Mobile No.' 
          className='mobile-input'
          name='phone'
          onChange={customerFormik.handleChange}
          value={customerFormik.values.phone}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              customerFormik.handleSubmit()
            }
          }}
          />
          
          {!showOTP && <span className="add-mobile pointer" onClick={()=>{customerFormik.handleSubmit()}}>Add</span>}
          {showOTP && <span className="add-mobile pointer" onClick={sendOTP}>Send OTP</span>}
          {customerFormik.errors.phone  ? <div className="error">{customerFormik.errors.phone}</div> : null}

          {otp && checkoutType === "customer" && <input type="text" 
          placeholder='OTP'
          className='mobile-input'
          name='otp'
          onChange={registerFormik.handleChange}
          value={registerFormik.values.otp}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              registerFormik.handleSubmit()
            }
          }}

          />
          }
          {otp && checkoutType === "customer" && <span className="add-mobile pointer" onClick={registerFormik.handleSubmit}>Register</span>}
          {otp && checkoutType === "customer" && registerFormik.errors.otp  ? <div className="error">{registerFormik.errors.otp}</div> : null}
          <input type="text" 
          placeholder='First Name'
          className='mobile-input'
          name='firstname'
          value={registerFormik.values.firstname}
          onChange={registerFormik.handleChange}
          />
          {registerFormik.errors.firstname  ? <div className="error">{registerFormik.errors.firstname}</div> : null}
          <input type="text" 
          placeholder='Last Name'
          className='mobile-input'
          name='lastname'
          value={registerFormik.values.lastname}
          onChange={registerFormik.handleChange}
          />
          {checkoutType === "customer" && registerFormik.errors.lastname  ? <div className="error">{registerFormik.errors.lastname}</div> : null}
          <input type="email" 
          placeholder='Email'
          className='mobile-input' 
          name='email'
          value={registerFormik.values.email}
          onChange={registerFormik.handleChange}
          />
          {registerFormik.errors.email  ? <div className="error">{registerFormik.errors.email}</div> : null}
          <input type="text" 
          placeholder='Coupon code'
          /> 

          {!customer?._id && <div className="netre-care create-account">
            <label className="container">Create account
              <input type="checkbox" 
              onChange={e => handleCreateAccountCheckboxChange(e)} />
              <span className="checkmark"></span>
            </label>
          </div>}


        </div>

        {products.length > 0 && <div className="frame-details">
          <div className="title">Frame details</div>
          {products.map((item, index)=><input key={index} type="text" placeholder='Frame Name' value={item?.name} readOnly disabled/>)}
        </div>}

        <div className="billing-details">
          <div className="title">Billing details</div>

          <div className="netre-care create-account">
            <label className="container">Pickup From Store
              <input type="checkbox" 
              defaultChecked
              value={isPickupFromStore}
              onChange={e => handlePickFromStoreChange(e)} 
              />
              <span className="checkmark"></span>
            </label>
          </div>
          {!isPickupFromStore && <div className="billing-grid">
            <input className='item-1' type="text" 
            placeholder='Address 1'
            name='address1'
            onChange={adressFormik.handleChange}
            value={adressFormik.values.address1}
            />
            {adressFormik.errors.address1  ? <div className="error">{adressFormik.errors.address1}</div> : null}
            <input className='item-2' type="text" 
            placeholder='Address 2'
            name='address2'
            onChange={adressFormik.handleChange}
            value={adressFormik.values.address2}
            />
            {adressFormik.errors.address2  ? <div className="error">{adressFormik.errors.address2}</div> : null}
            <input className='item-2' type="text" 
            placeholder='City'
            name='city'
            onChange={adressFormik.handleChange}
            value={adressFormik.values.city}
              />
              {adressFormik.errors.city  ? <div className="error">{adressFormik.errors.city}</div> : null}
            <input className='item-2' type="text" 
            placeholder='Pincode' 
            name='zipCode'
            onChange={adressFormik.handleChange}
            value={adressFormik.values.zipCode}
            />
            {adressFormik.errors.zipCode  ? <div className="error">{adressFormik.errors.zipCode}</div> : null}
          </div>}
        </div>

        <div className="lens-details">
          <div className="title">Lens details</div>
          <div className="lens-grid">
            <input className='item-1' type="text" placeholder='Lens Brand' 
            name="brandName"
            onChange={lensFormik.handleChange}
            value={lensFormik.values.brandName}
            />
            <input className='item-2' type="text" placeholder='Lens Type' 
            name='lensType'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.lensType}
            />
            <input className='item-3' type="text" placeholder='LE' readOnly disabled/>
            <input className='item-4' type="text" placeholder='SPH' 
            name='leftEyeSphere'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.leftEyeSphere}
            />
            <input className='item-5' type="text" placeholder='CYL' 
            name='leftEyeCylinder'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.leftEyeCylinder}
            />
            <input className='item-6' type="text" placeholder='AXIS' 
            name='leftEyeAxis'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.leftEyeAxis}
            />
            <input className='item-7' type="text" placeholder='RE' readOnly disabled/>
            <input className='item-8' type="text" placeholder='SPH' 
            name='rightEyeSphere'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.rightEyeSphere}
            />
            <input className='item-9' type="text" placeholder='CYL' 
            name='rightEyeCylinder'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.rightEyeCylinder}
            />
            <input className='item-10' type="text" placeholder='AXIS' 
            name='rightEyeAxis'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.rightEyeAxis}
            />
            <input className='item-11' type="text" placeholder='Lens Price' 
            name='lensPrice'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.lensPrice}
            />
            <input className='item-12' type="text" placeholder='Offer(%/&#8377;)' 
            name='discount'
            onChange={lensFormik.handleChange}
            value={lensFormik.values.discount}
            />
          </div>
        </div>
        
        {products.length > 0  && 
        <div className="order-summary"> 
          <div className="title mont-bold">Order summary</div>
            {[...products].reverse().map((item, index) => (
          <div className="container" key={index}>
            <div className="box" key={index}>
              <div className="img-box">
                <img src={item.images ? item?.images[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : ""} />
              </div>
              <div className="desc">
                <div className="title">{item?.name}</div>
                <div className="detail">
                <div className="stock">SKU:&nbsp; {item?.sku}</div>
                  <div className="stock">Qty:&nbsp; 1</div>

                </div>
              </div>
            </div>
            <div className="total">
              <div className="delete-btn" onClick={()=>{handleRemoveProductFromCart(item)}}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M304.62-160q-26.85 0-45.74-18.88Q240-197.77 240-224.62V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.38q0 27.62-18.5 46.12Q683-160 655.38-160H304.62ZM680-720H280v495.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h350.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-720ZM392.31-280h40v-360h-40v360Zm135.38 0h40v-360h-40v360ZM280-720v520-520Z"/></svg></div>
              <div className="">&#8377;&nbsp;{item?.price}</div>
              </div>
            
          </div>) 
        )
      }
          <div className="text total-amount">
            <div className="label mont-bold">Total amount</div>
            <div className="price mont-bold">&#8377;&nbsp;{total}</div>
          </div>
        
        </div>}

        <div className="payment-details">
          <div className="title">Payment Details</div>
          <div className="payment-radios">
            <PaymentOptions options={paymentOptions}/>
          </div>
        </div>
        <div className="place-order">
          <div className='btn' onClick={placeOrder}>PLACE ORDER</div>
        </div>
      </div>
    </div>
  )
}

export default NewOrder


