import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { loginUser } from "../features/user/userSlice";
import { Header } from "../home/Home";
import { ContactLast, Logo } from "../signup/Signup";
import "./Login.scss";
import { useFormik } from "formik";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // redux state
  const { loading, error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleLoginEvent = (values) => {
    let userCredentials = {
      email: values?.email,
      password: values?.password,
    };

    dispatch(loginUser(userCredentials)).then((result) => {
      if (result.payload) {
        setEmail("");
        setPassword("");
        navigate("/");
      }
    });
  };
  useEffect(() => {
    if (error) {
      if (error === "Request failed with status code 401") {
        toast.error("Access Denied! Invalid Credentials", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }else if (error === "Request failed with status code 403") {
        toast.error("Your account is under verification", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        toast.error(error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    }
  }, [error]);

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async function (values) {
      handleLoginEvent(values);
    },
    validate: (values) => {
      let errors = {};
      if (!values?.email) {
        errors.email = "Email/Store ID is Required!";
      } 
      // else if (!isValidEmail(values?.email)) {
      //   errors.email = "Email is not valid!";
      // } 
      else if (!values?.password) {
        errors.password = "Password is Required!";
      }
      return errors;
    },
  });

  return (
    <form className="Login" onSubmit={(e) => e.preventDefault()}>
      <Logo />
      <Header />
      <div className="text-1 font-sabon">Login</div>
      <div className="text-2">
        To login to existing account fill in the details
      </div>
      <div className="text-2 member">
        New ? <Link to="/signup">Signup</Link>{" "}
      </div>
      <div className="fields">
        <div className="field">
          <input
            type="text"
            placeholder="Email / Store ID"
            name="email"
            onChange={loginFormik.handleChange}
            onBlur={loginFormik.handleBlur}
            value={loginFormik.values.phoneNo}
          />
          {loginFormik.errors.email && loginFormik.touched.email ? (
            <div className="error-msg">{loginFormik.errors.email}</div>
          ) : null}
        </div>
        <div className="field">
          <input
            type="password"
            placeholder="Password"
            name="password"
            onChange={loginFormik.handleChange}
            onBlur={loginFormik.handleBlur}
            value={loginFormik.values.phoneNo}
          />
          {loginFormik.errors.password && loginFormik.touched.password ? (
            <div className="error-msg">{loginFormik.errors.password}</div>
          ) : null}
        </div>
      </div>
      <div className="create-acc-btn">
        <button
          onClick={loginFormik.handleSubmit}
          className="btn"
          type="submit"
        >
          {loading ? "Loading..." : "LOGIN"}
        </button>
      </div>
      <div className="fp_link">
        <Link to={"/forgot-password"}> Forgot Password ? </Link>
      </div>
      {/* <div className="text-5"><div className='box'><Link to="/account" className='btn'>Forgot your password ?</Link></div></div> */}
      <div className="or-mobile">
        <div className="or">OR</div>
        <div className="hr-line"></div>
      </div>
      <Link to="/signup" className="or-text">
        Sign Up
      </Link>
      <ContactLast />
    </form>
  );
}

export default Login;

export function isValidEmail(email) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}
