import React, { useState } from 'react'
import './UploadBills.scss'
function UploadBills() {
  // on click of add more btn, add more form and save the data for every added form into an array
  const [form, setForm] = useState([
    {
      sku: '',
      amount: '',
      customerName: '',
      customerNumber: '',
      billPdf: ''
    }
  ])
  console.log(form)
  
  const handleAddMore = () => {
    setForm([...form, 
      {
        sku: '',
        amount: '',
        customerName: '',
        customerNumber: '',
        billPdf: ''
      }])
  }
  return (
    <div className='UploadBills'>
      <h1>Upload Bills</h1>
      <h3 className='text-2'>To order/replace frames upload bills</h3>
      <div className="container">
        <div className="left">
        {/* add more form btn */}
          <div className="add-more">
            <button className='btn' onClick={handleAddMore}>Add More</button>
          </div>
          {/* render UploadForm component */}
          {form.map((item, index) => (
            <UploadForm key={index} handleAddMore={handleAddMore}/>
          ))}
        </div>
        <div className="right">
          <h2>Bill Summary</h2>
          {/* forms data display */}
          {form.map((item, index) => (
            <div className="accordian" key={index}>
              <div className="accordian-header">
                <h3>Bill {index+1}</h3>
                <div className="accordian-icon">+</div>
              </div>
              {/* <div className="accordian-content">
                <p>SKU: {item.sku}</p>
                <p>Bill Amount: {item.amount}</p>
                <p>Customer Name: {item.customerName}</p>
                <p>Customer Number: {item.customerNumber}</p>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UploadBills

const UploadForm = (props) => {
  // form fields save to state and pass the data to parent component
  const [sku, setSku] = useState('')
  const [amount, setAmount] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerNumber, setCustomerNumber] = useState('')
  const [billPdf, setBillPdf] = useState('')

  const handleSku = (e) => {
    setSku(e.target.value)
    handleFormSubmit()
  }
  const handleAmount = (e) => {
    setAmount(e.target.value)
    handleFormSubmit()
  }
  const handleCustomerName = (e) => {
    setCustomerName(e.target.value)
    handleFormSubmit()
  }
  const handleCustomerNumber = (e) => {
    setCustomerNumber(e.target.value)
    handleFormSubmit()
  }
  const handleBillPdf = (e) => {
    setBillPdf(e.target.value)
    handleFormSubmit()
  }
  
  const handleFormSubmit = () => {
    const data = {
      sku: sku,
      amount: amount,
      customerName: customerName,
      customerNumber: customerNumber,
      billPdf: billPdf
    }
    console.log(data)  
  }

  return (
    <form className="upload-form" onSubmit={()=> {return false}} data={props.data}>
      <input type="text" placeholder="SKU" onChange={handleSku} />
      <input type="text" placeholder="Amount" onChange={handleAmount}/>
      <input type="text" placeholder="Customer Name" onChange={handleCustomerName}/>
      <input type="text" placeholder="Customer Number" onChange={handleCustomerNumber}/>
      {/* <input type="file" placeholder="Bill PDF" onChange={handleBillPdf}/> */}
      <div className="pdf-upload">Upload Bill</div>
    </form>
  )
}
