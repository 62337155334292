import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { loginUser } from "../features/user/userSlice";
import { Header } from "../home/Home";
import { ContactLast, Logo } from "../signup/Signup";
import "./forgotPassword.scss";
import { useFormik } from "formik";
import { isValidEmail } from "../login/Login";
import axios from "axios";

const ForgotPassword = () => {
  const navigate = useNavigate();

  // redux state
  const { loading, error } = useSelector((state) => state.user);

  const handleLoginEvent = async (values) => {
    try {
      const response = await axios(
        process.env.REACT_APP_BASE_URL + `retailer/forgot-password`,
        {
          method: "PUT",
          data: {
            retailerEmail: values.email,
          },
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      
      navigate("/login");
      toast.success(response?.data?.message);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    }
  };
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const forgotFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async function (values) {
      handleLoginEvent(values);
    },
    validate: (values) => {
      let errors = {};
      if (!values?.email) {
        errors.email = "Email is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Email is not valid!";
      }
      return errors;
    },
  });

  return (
    <form className="forgot_password" onSubmit={(e) => e.preventDefault()}>
      <Logo />
      <Header />
      <div className="text-1 font-sabon">Forgot Password</div>
      <div className="text-2">
        To login to existing account fill in the details
      </div>
      <div className="text-2 member">
        New ? <Link to="/signup">Signup</Link>{" "}
      </div>
      <div className="fields">
        <div className="field">
          <input
            type="text"
            placeholder="Email"
            name="email"
            onChange={forgotFormik.handleChange}
            onBlur={forgotFormik.handleBlur}
            value={forgotFormik.values.email}
          />
          {forgotFormik.errors.email && forgotFormik.touched.email ? (
            <div className="error-msg">{forgotFormik.errors.email}</div>
          ) : null}
        </div>
      </div>
      <div className="create-acc-btn">
        <button
          onClick={forgotFormik.handleSubmit}
          className="btn"
          type="submit"
        >
          {loading ? "Loading..." : "SEND"}
        </button>
      </div>
      {/* <div className="text-5"><div className='box'><Link to="/account" className='btn'>Forgot your password ?</Link></div></div> */}
      <div className="or-mobile">
        <div className="or">OR</div>
        <div className="hr-line"></div>
      </div>
      <Link to="/signup" className="or-text">
        Sign Up
      </Link>
      <ContactLast />
    </form>
  );
};

export default ForgotPassword;
