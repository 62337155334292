import React from "react";
import { Logo } from "../signup/Signup";
import "./verification.scss";

const Verification = () => {
  return (
    <div className="verification">
      <Logo />
      <div className="verification_text">
        Your account is under review. Please wait; It will take 2 to 3 business
        days.
      </div>
    </div>
  );
};

export default Verification;
