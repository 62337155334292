import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getUser, Header } from '../home/Home'
import { Logo } from '../signup/Signup'
import './Account.scss'
function Account() {
    //login
    const [user, setUser] = useState(getUser());
    const navigate = useNavigate();
    useEffect(()=>{
      if(!user){
        navigate('/login')
      }
    },[user])
    //login end

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Define state variables to manage editing state
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({
      retailerName: '',
      shopName: '',
      address: '',
      zipcode: '',
      phoneNumber: '',
      email: '',
      gstNumber: ''
    });
  
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + '/retailer/profile',{
          headers:{
            'x-api-key': process.env.REACT_APP_API_KEY,
            "Authorization": `Bearer ${user}`,
          }
        });
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    // update with put method
    const updateData = async () => {
      try {
        const response = await axios.put(process.env.REACT_APP_BASE_URL + '/retailer/profile',editedData,{
          headers:{
            'x-api-key': process.env.REACT_APP_API_KEY,
            "Authorization": `Bearer ${user}`,
          }
        });
        console.log(response)
        fetchData();
        setIsEditing(false);
      }
      catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    useEffect(() => {
  
      fetchData();
    }, []);
    
    

    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }


  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to handle edit button click
  const handleEditClick = () => {
    setIsEditing(true);
    // Set edited data to current data when edit button clicked
    setEditedData(data);
  };

  // Function to handle update button click
  const handleUpdateClick = () => {
    // After successful update, set isEditing to false
    // console.log(editedData);
    updateData();
    setIsEditing(false);
  };

  return (
    <div className='Account'>
        <Logo/>
        <Header/>
        <div className="text-1 font-sabon">Welcomes you on board</div>
        <div className="box">
          <div className="account-label">
          <div>Your Account</div>  
          <div><span>Store Id&nbsp;-&nbsp;</span><span className='store-id'>{data.uniqueStoreId}</span></div>  
          </div>
          {isEditing ? (
            <div className='edit' onClick={handleUpdateClick}>Update</div>
          ) : (
            <div className='edit' onClick={handleEditClick}>Edit</div>
          )}
          <div className="fields">
            <div className="field">
              <div className='label'>Name</div>
              <input 
                type="text"
                name="retailerName"
                value={isEditing ? editedData.retailerName : data.retailerName}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
              
            </div>
            <div className="field">
              <div className='label'>Store Name</div>
              <input
                type='text'
                name='shopName'
                value={isEditing ? editedData.shopName : data.shopName}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
            </div>
            <div className="field">
              <div className='label'>Phone</div>
              <input
                type='number'
                name='phoneNumber'
                value={isEditing ? editedData.phoneNumber : data.phoneNumber}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
            </div>
            <div className="field">
              <div className='label'>Email Address</div>
              <input

                type='email'
                name='email'
                value={isEditing ? editedData.email : data.email}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
            </div>
            <div className="field">
              <div className='label'>Address</div>
              <input
                type='text'
                name='address'
                value={isEditing ? editedData.address : data.address}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
            </div>

            <div className="field">
              <div className='label'>Zipcode</div>
              <input
                type='number'
                name='zipcode'
                value={isEditing ? editedData.zipcode : data.zipcode}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
            </div>
            <div className="field">
              <div className='label'>GST No.</div>
              <input
                type='text'
                name='gstNumber'
                value={isEditing ? editedData.gstNumber : data.gstNumber}
                onChange={handleInputChange}
                readOnly={!isEditing}
              />
              {/* {isEditing ? (
                <div className='edit' onClick={handleUpdateClick}>Update</div>
              ) : (
                <div className='edit' onClick={handleEditClick}>Edit</div>
              )} */}
            </div>
            <div className="field"><div className='label'>Password</div>
            <input type="password"  value="12345678" readOnly/>
            {/* <div className='edit'>Change</div> */}
            </div>
          </div>
        </div> 
        <Link to="/" className="create-acc-btn">
          <button className="btn">GO TO HOME</button>
        </Link> 
    </div>
  )
}

export default Account