import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import icon from '../../assets/icons/WhatsApp.svg';

export const WhatsappIcon = (props) => {
  const [text, setText] = useState("Hello");
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes("/product")) {
      setText(`Hello, I want to know more about this product ${window.location.href}`);
    } else {
      setText("Hi, I want to know more about Netre B2B platform.");
    }
  }, [pathname]);

  return (
    <a style={{ position: "fixed", zIndex: 999999, bottom: document.body.scrollWidth > 1000 ? "30px" : "30px", right: "30px", height: "50px", width: "50px", transition: "all 0.3s ease-in-ou t" }}
      href={`https://wa.me/919824690055/?text=${text}`}
      target="_blank"
      className="whatsapp"
      onMouseOver={(e) => {
        e.target.style.transform = "scale(1.1)";
      }}
      onMouseLeave={(e) => {
        e.target.style.transform = "scale(1)";

      }}
    >
      <img src={icon} alt="whatsapp" style={{ height: "50px", width: "50px" }} />
    </a>
  );
};
