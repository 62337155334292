import { Home, getUser } from "./home/Home";
import { Route, Routes, useNavigate } from "react-router-dom";
import Account from "./account/Account";
import "./App.css";
import Login from "./login/Login";
import { NoMatch } from "./noMatch/NoMatch";
import { Signup } from "./signup/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./components/landing/Landing";
import { useEffect, useState } from "react";
import ScrollToTopPage from "./components/ScrollToTopPage";
import Verification from "./verification/Verification";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import ResetPassword from "./forgotPassword/ResetPassword";

function App() {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    // if(!user){
    //   navigate('/login')
    // }
  }, [user]);
  //login end
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/login"
          element={
            <>
              <Login />
            </>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <>
              <ForgotPassword />
            </>
          }
        />
        <Route
          exact
          path="/reset-password/:token"
          element={
            <>
              <ResetPassword />
            </>
          }
        />
        <Route
          exact
          path="/signup"
          element={
            <>
              <Signup />
            </>
          }
        />
        <Route
          exact
          path="/account"
          element={
            <>
              <Account />
            </>
          }
        />
        <Route
          exact
          path="/verification"
          element={
            <>
              <Verification />
            </>
          }
        />
        <Route
          exact
          path="/orders/new"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/orders/current"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/orders/completed"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/orders/completed/return"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/orders/history"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/inquiries"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/customers"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/customers/return"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/marketing"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/policy"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/inventory/:toggleValue"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/inventory/uploadbills"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/virtual-tryon"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/product/virtual/:id"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/product/instore/:id"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/dashboard"
          element={
            <>
              <Landing />
            </>
          }
        />
        <Route
          exact
          path="/"
          element={
            <>
              <Home />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <NoMatch />
            </>
          }
        />
        <Route
          path="/360"
          element={
            <>
              <Landing />
            </>
          }
        />
      </Routes>
      <ToastContainer />
      <ScrollToTopPage />
    </div>
  );
}

export default App;
