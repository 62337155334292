import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetProductsQuery,
  useGetRetailerProductsQuery,
} from "../../features/product/productSlice";
import { getUser } from "../../home/Home";
import ProductCard from "../productCard/ProductCard";

import "./VirtualTryon.scss";
function VirtualTryon({}) {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const params = useParams();
  const [storeType, setStoreType] = useState(
    params?.toggleValue === "in-store" ? "In Store" : "Virtual"
  );
  useEffect(() => {
    setStoreType(params?.toggleValue === "in-store" ? "In Store" : "Virtual");
  }, [params]);

  return (
    <div className="virtual-tryon">
      <div className="container">
        <div className="store-types">
          <div
            className={
              storeType === "Virtual" ? "store-type active" : "store-type"
            }
            onClick={() => {
              navigate("/inventory/virtual");
            }}
          >
            Virtual
          </div>
          <div
            className={
              storeType === "In Store" ? "store-type active" : "store-type"
            }
            onClick={() => {
              navigate("/inventory/in-store");
            }}
          >
            In Store
          </div>
          <div className="border"></div>
          {/* {storeType === "Virtual" && props.route === "/b2b/virtual-tryon" && <div className="inquiry-tray-btn">INQUIRY TRAY</div>} */}
          {/* {storeType === "Virtual" && <div className="inquiry-tray-btn">INQUIRY TRAY</div>} */}
        </div>
      </div>
      {storeType === "Virtual" && <VirtualProducts storeType={"Virtual"} />}
      {storeType === "In Store" && <InStoreProducts storeType={"In Store"} />}
    </div>
  );
}

export default VirtualTryon;

export function VirtualProducts(props) {
  const navigate = useNavigate();
  // const {
  //     data: productsData,
  //     isLoading,
  //     isSuccess,
  //     isError,
  //     error,
  //   } = useGetProductsQuery();
  //   if(isError && error.status === 401){
  //     navigate('/login');
  //   }
  const [newData, setNewData] = useState();
  const [products, setProducts] = useState([]);

  const [page, setPage] = useState(1);
  const {
    data: productsData,
    isLoading,
    isFetching,
  } = useGetProductsQuery(page);

  useEffect(() => {
    if (productsData) {
      setNewData(productsData);
    }
  }, [productsData]);

  useEffect(() => {
    if (newData) {
      setProducts([...products, ...newData.products]);
    }
  }, [newData]);

  useEffect(() => {
    const rightArea = document.querySelector(".right-area");
    const footer = document.querySelector(".footer");

    if (!rightArea) return;

    const handleScroll = () => {
      if (
        rightArea.scrollTop + rightArea.clientHeight >=
          rightArea.scrollHeight - 50 &&
        !isFetching
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    rightArea.addEventListener("scroll", handleScroll);
    return () => rightArea.removeEventListener("scroll", handleScroll);
  }, [isFetching]);

  return (
    <div className="cards">
      {isLoading && <div>Loading..</div>}
      {products &&
        products.map((item) => (
          <ProductCard
            key={item._id}
            product={item}
            route={"/virtual-tryon"}
            storeType={"Virtual"}
            {...props}
          />
        ))}
    </div>
  );
}

export function InStoreProducts(props) {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const {
    data: productsData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRetailerProductsQuery({ bearerToken: user });
  if (isError && error.status === 401) {
    navigate("/login");
  }

  return (
    <div className="cards">
      {isLoading && <div>Loading..</div>}
      {productsData && productsData.data.inventory.length === 0 && (
        <div>No products available </div>
      )}
      {productsData &&
        [...productsData.data.inventory]
          .reverse()
          .map((item) => (
            <ProductCard
              key={item._id}
              product={item}
              storeType={"In Store"}
              {...props}
            />
          ))}
    </div>
  );
}
