import { createSlice } from '@reduxjs/toolkit';

const storeInquirySlice = createSlice({
  name: 'storeInquiry',
  initialState: {
    products: [],
    customer: null,
  },
  reducers: {
    addStoreInquiryProducts: (state, action) => {
        state.products = action.payload;
    },
    removeProductFromStoreInquiry: (state, action) => {
      state.products = state.products.filter((product) => product._id !== action.payload);
    },
    setStoreInquiryCustomer: (state, action) => {
      state.customer = action.payload;
  },
  }
});

export const {addStoreInquiryProducts, removeProductFromStoreInquiry, setStoreInquiryCustomer} = storeInquirySlice.actions;
export default storeInquirySlice.reducer;
