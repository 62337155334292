import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  productSlice,
  useAddProductsToRetailerInventoryMutation,
  useExchangeProductsFromRetailerInventoryMutation,
  useGetProductsQuery,
  useGetRetailerInfoQuery,
  useGetRetailerProductsQuery,
  useReturnProductsFromRetailerInventoryMutation,
} from "../../features/product/productSlice";
import { getUser } from "../../home/Home";
import { InStoreProducts, VirtualProducts } from "../VirtualTryon/VirtualTryon";
import "./Inventory.scss";
import { Bounce, toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addProduct,
  clearProducts,
  removeProduct,
} from "../../features/inventory/selectedProductsSlice";
function Inventory() {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const {
    data: retailerProductsData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRetailerProductsQuery({ bearerToken: user });
  if (isError && error.status === 401) {
    navigate("/login");
  }

  const {
    data: productsData,
    isSuccessProducts,
    isErrorProducts,
    errorProducts,
  } = useGetProductsQuery();
  if (isErrorProducts && errorProducts.status === 401) {
    navigate("/login");
  }
  const params = useParams();

  const [storeType, setStoreType] = useState(
    params?.toggleValue === "in-store" ? "In Store" : "Virtual"
  );
  const [operation, setOperation] = useState(null);
  const [showReturnReasonPopup, setShowReturnReasonPopup] = useState(false);
  const [returnReason, setReturnReason] = useState(null);
  const [showReturnPage, setShowReturnPage] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setStoreType(params?.toggleValue === "in-store" ? "In Store" : "Virtual");
  }, [params]);

  // array of products selected to add to inventory
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(productSlice.util.invalidateTags(['InventoryUpdated']));
  },[])
  const selectedProducts = useSelector(
    (state) => state.selectedProducts.selectedProducts
  );
  // the user will select the input select dropdown and the value and product id will be added to the selectedProducts array will also check for null values
  // const handleSelectChange = (e, product) => {
  //   const value = e.target.value;
  //   const id = product._id;
  //   if (
  //     value === "null" ||
  //     value === null ||
  //     value === undefined ||
  //     value === "" ||
  //     value === 0 ||
  //     value === "0"
  //   ) {
  //     setSelectedProducts(
  //       selectedProducts.filter((product) => product.product_id !== id)
  //     );
  //   } else {
  //     if (selectedProducts.find((product) => product.product_id === id)) {
  //       setSelectedProducts(
  //         selectedProducts.map((product) => {
  //           if (product.product_id === id) {
  //             return { ...product, quantity: value };
  //           }
  //           return product;
  //         })
  //       );
  //     } else {
  //       setSelectedProducts([
  //         ...selectedProducts,
  //         {
  //           product_id: id,
  //           name: product.title ? product.title.en : product.name,
  //           images: product.image,
  //           sku: product.sku,
  //           quantity: value,
  //           price: product.prices ? product.prices.price : product.price,
  //           variants: product.variants,
  //           attributes: product.attributes,
  //           isCombination: product.isCombination,
  //           orderStatus: "Placed",
  //           cluster: product.cluster,
  //           url_360: product.url_360,
  //         },
  //       ]);
  //     }
  //   }
  // };

  const handleSelectChange = (e, product) => {
    const value = e.target.value;
    const id = product._id;
    if (
      value === "null" ||
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    ) {
      dispatch(removeProduct(id));
    } else {
      const productToAdd = {
        product_id: id,
        name: product.title ? product.title.en : product.name,
        images: product.image,
        sku: product.sku,
        quantity: value,
        price: product.prices ? product.prices.price : product.price,
        variants: product.variants,
        attributes: product.attributes,
        isCombination: product.isCombination,
        orderStatus: "Placed",
        cluster: product.cluster,
        url_360: product.url_360,
      };
      dispatch(addProduct(productToAdd));
    }
  };

  const handleSelectChangeInstore = (e, product) => {
    const value = e.target.value;
    const id = product._id;
    if (
      value === "null" ||
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0"
    ) {
      dispatch(removeProduct(id));
    } else {
      const productToAdd = {
        product_id: id,
        name: product.title ? product.title.en : product.name,
        images: product.images,
        sku: product.sku,
        quantity: value,
        price: product.prices ? product.prices.price : product.price,
        variants: product.variants,
        attributes: product.attributes,
        isCombination: product.isCombination,
        orderStatus: "Placed",
        cluster: product.cluster,
        url_360: product.url_360,
      };
      dispatch(addProduct(productToAdd));
    }
    // const value = e.target.value;
    // const id = product.product_id;
    // if (
    //   value === "null" ||
    //   value === null ||
    //   value === undefined ||
    //   value === "" ||
    //   value === 0 ||
    //   value === "0"
    // ) {
    //   setSelectedProducts(
    //     selectedProducts.filter((product) => product.product_id !== id)
    //   );
    // } else {
    //   if (selectedProducts.find((product) => product.product_id === id)) {
    //     setSelectedProducts(
    //       selectedProducts.map((product) => {
    //         if (product.product_id === id) {
    //           return { ...product, quantity: value };
    //         }
    //         return product;
    //       })
    //     );
    //   } else {
    //     setSelectedProducts([
    //       ...selectedProducts,
    //       {
    //         product_id: id,
    //         name: product.title ? product.title.en : product.name,
    //         images: product.images,
    //         sku: product.sku,
    //         quantity: value,
    //         price: product.prices ? product.prices.price : product.price,
    //         variants: product.variants,
    //         attributes: product.attributes,
    //         isCombination: product.isCombination,
    //         orderStatus: "Placed",
    //         cluster: product.cluster,
    //         url_360: product.url_360,
    //       },
    //     ]);
    //   }
    // }
  };

  // remove a product from the selectedProducts array
  const handleRemoveProductForRemove = (id) => {
    // setSelectedProducts(
    //   selectedProducts.filter((product) => product.product_id !== id)
    // );
  };

  const handleRemoveProductFromOldExchangeProducts = (id) => {
    setProductsToExchange(
      productsToExchange.filter((product) => product.product_id !== id)
    );
  };

  const handleRemoveProductFromNewExchangeProducts = (id) => {
    setProductsToExchangeWith(
      productsToExchangeWith.filter((product) => product.product_id !== id)
    );
    dispatch(removeProduct(id));

  };

  const [
    exchangeProductsFromRetailerInventory,
    {
      isLoading: exchangeProductsLoading,
      isSuccess: exchangeProductsSuccess,
      isError: exchangeProductsError,
      error: exchangeProductsErrorData,
    },
  ] = useExchangeProductsFromRetailerInventoryMutation();

  const exchangeRetailerProducts = () => {
    if(productsToExchangeWithTotal === 0){
      toast.error("Please select products to exchange with");
      return;
    }
    if(productsToExchangeTotal === 0){
      toast.error("Please select products to exchange");
      return;
    }
    if(productsToExchangeWithTotal !== productsToExchangeTotal){
      toast.error("Please select the same number of products");
      return;
    }
    const body = {
      products: productsToExchangeWith,
      exchangedProducts: productsToExchange,
      exchangeReason: "",
    };
    exchangeProductsFromRetailerInventory({
      body: body,
      bearerToken: user,
    }).then((result) => {
      if (result.data) {
        toast.success(result.data.message);
        navigate("/orders/history");
        dispatch(clearProducts());
      }
      if (result.error) {
        toast.error(result.error.data.message);
      }
    });
  };

  //max number of products that can be added to inventory
  const {
    data: retailerData,
    isLoading: isLoadingRetailer,
    isSuccess: isSuccessRetailer,
    isError: isErrorRetailer,
    error: errorRetailer,
  } = useGetRetailerInfoQuery({ bearerToken: user });
  const maxProducts = retailerData?.data?.stockLimit;
  //current number of products in inventory from the retailerProductsData, is the sum of the quantity of each product in retailerProductsData
  const currentProducts = retailerProductsData?.data.inventory.reduce(
    (acc, product) => {
      return acc + parseInt(product.quantity);
    },
    0
  );
  // updated number of products in inventory
  // updated products are the sum of the current product and the sum of the value of each selected product
  const updatedProducts = selectedProducts.reduce((acc, product) => {
    return acc + parseInt(product.quantity);
  }, currentProducts);

  const selectedProductTotal = selectedProducts.reduce((acc, product) => {
    return acc + parseInt(product.quantity);
  }, 0);

  useEffect(() => {
    //empty selected products array if store type is changed
    // setSelectedProducts([]);
  }, [storeType]);

  // give a warning of Max 30 products can be added to inventory nd navigate to /inventory/uploadbills
  useEffect(() => {
    // alert(operation)
    if (updatedProducts > maxProducts && operation != "Return") {
      toast.error(`Max ${maxProducts} products can be added to inventory`);
      // navigate('/inventory/uploadbills')
    }
  }, [updatedProducts]);

  const [
    addProductsToRetailerInventory,
    {
      isLoading: addProductsLoading,
      isSuccess: addProductsSuccess,
      isError: addProductsError,
      error: addProductsErrorData,
    },
  ] = useAddProductsToRetailerInventoryMutation();
  const handleConfirmClick = () => {
    // send the selected products to the server
    // if successful, set updateSuccess to true
    //if selected products are empty toast and return
    if (selectedProducts.length === 0) {
      toast.error("Please select products to add");
      return;
    }
    if (updatedProducts > maxProducts) {
      toast.error(`Max ${maxProducts} products can be added to inventory`);
      return;
    }
    const body = {
      products: selectedProducts,
    };

    // send using addProductsToRetailerInventory mutation set update success to true if mutation is successful handle bad request and show toasts
    addProductsToRetailerInventory({ products: body, bearerToken: user }).then(
      (result) => {
        if (result.data) {
          // setUpdateSuccess(true);
          // setSelectedProducts([]);

          toast.success(result.data.message);
          navigate("/orders/history");
          dispatch(clearProducts());
        }
        if (result.error) {
          toast.error(result.error.data.message);
        }
      }
    );
  };

  const handleReturnClick = () => {
    if (selectedProducts.length === 0) {
      toast.error("Please select products to Return");
      return;
    }
    setShowReturnReasonPopup(true);
  };
  const handleReturnSubmit = (values) => {
    // send the selected products to the server
    if (values.reasons.length === 0) {
      if (!values.toggle) {
        toast.error("Please select a reason for return");
        return;
      }
      if (
        (!values.toggle && values.other === "") ||
        (values.toggle && values.other === "")
      ) {
        toast.error("Please select a reason for return");
        return;
      }
    }
    // // make a string of the reasons array and append the other field to it
    // const body ={
    //   products: selectedProducts,
    //   returnReason: values.reasons.length > 0 ? values.reasons.join(', ') + (values.toggle ? `, ${values.other}` : '') : values.other
    // }
    setReturnReason(
      values.reasons.length > 0
        ? values.reasons.join(", ") + (values.toggle ? `, ${values.other}` : "")
        : values.other
    );
    setShowReturnPage(true);
    setShowReturnReasonPopup(false);
  };
  // useReturnProductsFromRetailerInventoryMutation
  const [
    returnProductsFromRetailerInventory,
    {
      isLoading: returnProductsLoading,
      isSuccess: returnProductsSuccess,
      isError: returnProductsError,
      error: returnProductsErrorData,
    },
  ] = useReturnProductsFromRetailerInventoryMutation();
  const placeReturnOrder = () => {
    if (selectedProducts.length === 0) {
      toast.error("Please select products to Return");
      return;
    }
    const body = {
      products: selectedProducts,
      // make a string of the reasons array and append the other field to it
      returnReason: returnReason ? returnReason : "",
    };
    returnProductsFromRetailerInventory({ body: body, bearerToken: user }).then(
      (result) => {
        if (result.data) {
          // setUpdateSuccess(true);
          // setSelectedProducts([]);
          toast.success(result.data.message);
          navigate("/orders/history");
        }
        if (result.error) {
          toast.error(result.error.data.message);
        }
      }
    );
  };

  const [productsToExchange, setProductsToExchange] = useState([]);

  const productsToExchangeTotal = productsToExchange.reduce((acc, product) => {
    return acc + parseInt(product.quantity);
  }, 0);
  
  const [productsToExchangeWith, setProductsToExchangeWith] = useState([]);

  const productsToExchangeWithTotal = productsToExchangeWith.reduce((acc, product) => {
    return acc + parseInt(product.quantity);
  }, 0);

  const handleExchangeClick = () => {
    if (selectedProducts.length === 0) {
      toast.error("Please select products to Exchange");
      return;
    }
    setProductsToExchange(selectedProducts);
    dispatch(clearProducts());
    navigate("/inventory/virtual");
  };

  const handleProductsToExchangeWithClick = () => {
    if (selectedProducts.length === 0) {
      toast.error("Please select products to Exchange with");
      return;
    }
    // the length of selected products should be the same as productsToExchange
    // if not toast and return
    if (selectedProductTotal !== productsToExchangeTotal) {
      toast.error("Please select the same number of products");
      return;
    }
    setOperation("Exchange");
    setProductsToExchangeWith(selectedProducts);
    dispatch(clearProducts());
  };

  console.log(
    "selectedProducts --->",
    selectedProducts,
    "selectedProductTotal --->",
    selectedProductTotal,
    "productsToExchangeTotal --->",
    productsToExchangeTotal,
    "productsToExchange ---->",
    productsToExchange,
    "productsToExchangeWith --->",
    productsToExchangeWith,
    "productsToExchangeWithTotal --->",
    productsToExchangeWithTotal
  );

  return (
    <div className="inventory">
      {operation === "Exchange" && (
        <ExchangeReview
          productsToExchange={productsToExchange}
          productsToExchangeWith={productsToExchangeWith}
          setProductsToExchange={setProductsToExchange}
          setProductsToExchangeWith={setProductsToExchangeWith}
          setOperation={setOperation}
          setStoreType={setStoreType}
          handleRemoveProductFromOldExchangeProducts={
            handleRemoveProductFromOldExchangeProducts
          }
          handleRemoveProductFromNewExchangeProducts={
            handleRemoveProductFromNewExchangeProducts
          }
          exchangeRetailerProducts={exchangeRetailerProducts}
        />
      )}
      {!showReturnPage && operation != "Exchange" && (
        <div className="container">
          <div className="store-types">
            <div
              className={
                storeType === "Virtual" ? "store-type active" : "store-type"
              }
              onClick={() => {
                navigate("/inventory/virtual");
                setOperation("Add");
              }}
            >
              Virtual
            </div>
            <div
              className={
                storeType === "In Store" ? "store-type active" : "store-type"
              }
              onClick={() => {
                navigate("/inventory/in-store");
                setOperation(null);
              }}
            >
              In Store
            </div>
            <div className="border"></div>
            {/* {storeType === "Virtual" && location.pathname === "/virtual-tryon" && <div className="inquiry-tray-btn">INQUIRY TRAY</div>} */}
            {/* {storeType === "Virtual" && <div className="inquiry-tray-btn">INQUIRY TRAY</div>} */}
          </div>
        </div>
      )}
      {(location.pathname === "/inventory/virtual" ||
        location.pathname === "/inventory/in-store") &&
        storeType === "In Store" &&
        !operation && (
          <div className="container update-btns">
            {/* <div className="available-text">{retailerProductsData?.data && retailerProductsData?.data.inventory.length && retailerProductsData?.data.inventory.length ? `${retailerProductsData?.data.inventory.length}/${maxProducts} Available` : "Loading..." }</div> */}
            <div className="available-text">
              {currentProducts && maxProducts
                ? `${currentProducts}/${maxProducts} Available`
                : ""}
            </div>
            <div className="add-exchange">
              <div
                className="add-more-frames"
                onClick={() => {
                  navigate("/inventory/virtual");
                  setOperation("Add");
                  setProductsToExchange([]);
                  setProductsToExchangeWith([]);
                }}
              >
                ADD MORE FRAMES
              </div>
              {/* <div className="exchange" onClick={()=>{setOperation('Return')}}>RETURN / EXCHANGE</div> */}
              <div
                className="exchange"
                onClick={() => {
                  setOperation("Return");
                }}
              >
                EXCHANGE
              </div>
            </div>
          </div>
        )}
      {(location.pathname === "/inventory/virtual" ||
        location.pathname === "/inventory/in-store") &&
        storeType === "Virtual" &&
        operation != "Exchange" &&
        !updateSuccess && (
          <div className="update-btns">
            <div
              className="add-frame-text"
              onClick={() => {
                navigate("/inventory/in-store");
                setOperation(null);
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z" />
                </svg>
              </span>{" "}
              <span className="mont-bold">
                Select frames you want to{" "}
                {productsToExchange.length > 0 ? "exchange with" : "add"}{" "}
              </span>{" "}
            </div>
          </div>
        )}

      {!showReturnPage &&
        (location.pathname === "/inventory/virtual" ||
          location.pathname === "/inventory/in-store") &&
        storeType === "In Store" &&
        operation === "Return" && (
          <>
            <div className="update-btns">
              {/* <div className='add-frame-text' onClick={()=>{setOperation(null)}}><span><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg></span> <span className='mont-bold'>Select frames you want to return / exchange</span>  */}
              <div
                className="add-frame-text"
                onClick={() => {
                  setOperation(null);
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                  >
                    <path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z" />
                  </svg>
                </span>{" "}
                <span className="mont-bold">
                  Select frames you want to exchange
                </span>
              </div>
            </div>
            <div className="container update-btns">
              <div className="available-text">
                {selectedProducts ? `${selectedProductTotal} selected` : ""}
              </div>
              <div className="add-exchange">
                {/* <div className="add-more-frames" onClick={handleReturnClick}>RETURN</div> */}
                <div className="exchange" onClick={handleExchangeClick}>
                  EXCHANGE
                </div>
              </div>
            </div>
          </>
        )}

      {showReturnReasonPopup && (
        <ReturnReasonPopup
          setShowReturnReasonPopup={setShowReturnReasonPopup}
          setReturnReason={setReturnReason}
          handleReturnSubmit={handleReturnSubmit}
        />
      )}

      {(location.pathname === "/inventory/virtual" ||
        location.pathname === "/inventory/in-store") &&
        storeType === "Virtual" &&
        !updateSuccess && (
          <div className="container update-btns">
            {productsToExchange.length < 1 && operation !== "Exchange" ? (
              <div className="available-text">
                {updatedProducts && maxProducts
                  ? `${updatedProducts}/${maxProducts} Available`
                  : ""}
              </div>
            ) : (
              ""
            )}
            {productsToExchange.length > 0 && operation !== "Exchange" && (
              <div className="available-text">{`${selectedProductTotal}/${productsToExchangeTotal} Selected`}</div>
            )}
            {/* empty div to ui justify space between */}
            {/* {productsToExchange.length > 0 && <div></div>} */}

            <div className="add-exchange">
              {productsToExchange.length < 1 && operation != "Exchange" && (
                <div className="add-more-frames" onClick={handleConfirmClick}>
                  CONFIRM
                </div>
              )}
              {productsToExchange.length > 0 && operation != "Exchange" && (
                <div
                  className="exchange"
                  onClick={handleProductsToExchangeWithClick}
                >
                  CONFIRM
                </div>
              )}
            </div>
          </div>
        )}

      {
        <ReturnReview
          selectedProducts={showReturnPage ? selectedProducts : null}
          selectedProductTotal={showReturnPage ? selectedProductTotal : null}
          setShowReturnPage={setShowReturnPage}
          placeReturnOrder={placeReturnOrder}
          handleRemoveProductForRemove={handleRemoveProductForRemove}
        />
      }

      {/* <SelectionPopup /> */}

      {storeType === "Virtual" && operation != "Exchange" && (
        <VirtualProducts
          storeType={storeType}
          handleSelectChange={handleSelectChange}
          operation={operation}
          productsToExchange={productsToExchange}
        />
      )}
      <div style={{ display: showReturnPage ? "none" : "block" }}>
        {storeType === "In Store" && (
          <InStoreProducts
            storeType={storeType}
            handleSelectChange={handleSelectChangeInstore}
            operation={operation}
          />
        )}
      </div>
    </div>
  );
}

export default Inventory;

function ReturnReasonPopup(props) {
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  // const [formData , setFormData] = useState({
  //   toggle: false,
  //   other: '',
  //   reasons: [],
  // })

  return (
    <div className="return-reason-popup">
      <div
        className="backdrop"
        onClick={() => {
          props.setShowReturnReasonPopup(false);
        }}
      ></div>
      <div className="container">
        <div className="return-reason">
          <div className="confirm-box">
            <div className="title">Select Reason</div>
          </div>
          <Formik
            initialValues={{
              toggle: false,
              other: "",
              reasons: [],
            }}
            onSubmit={async (values) => {
              await sleep(500);
              props.setReturnReason(values);
              props.handleReturnSubmit(values);
              // alert(JSON.stringify(values, null, 2));
            }}
          >
            {({ values }) => (
              <Form>
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="checkbox-grid"
                >
                  <label className="checkbox-container">
                    <Field
                      type="checkbox"
                      name="reasons"
                      value="Damaged Product"
                    />
                    <span className="checkmark"></span>
                    <div>Damaged Product</div>
                  </label>
                  <label className="checkbox-container">
                    <Field type="checkbox" name="reasons" value="Wrong Size" />
                    <span className="checkmark"></span>
                    <div>Wrong Size</div>
                  </label>
                  <label className="checkbox-container">
                    <Field
                      type="checkbox"
                      name="reasons"
                      value="Wrong Product"
                    />
                    <span className="checkmark"></span>
                    <div>Wrong Product</div>
                  </label>
                  <label className="checkbox-container other">
                    <Field type="checkbox" name="toggle" />
                    <span className="checkmark"></span>
                    {/* <div>Other</div> */}
                    {/* if values.toggle is true then make a text field*/}
                    {
                      <Field
                        type="textarea"
                        name="other"
                        disabled={!values.toggle}
                        placeholder="Specify Other"
                        style={{
                          opacity: "unset",
                          height: "unset",
                          width: "unset",
                          margin: "0",
                          paddingLeft: "0",
                        }}
                      />
                    }
                  </label>
                  <div></div>
                </div>

                <div className="confirm-box">
                  <button className="confirm" type="submit">
                    CONFIRM
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

function ReturnReview(props) {
  const handleRemoveProduct = (id) => {
    //ask for confirmation
    // handleRemoveProductForRemove
    // Ask for confirmation before canceling the order
    const confirmed = window.confirm(
      "Are you sure you want to remove this product?"
    );

    // If user confirms, proceed with cancelation
    if (confirmed) {
      props.handleRemoveProductForRemove(id);
    } else {
    }
  };
  if (!props.selectedProducts) {
    return null;
  }
  return (
    <div className="return-review">
      <div className="update-btns">
        <div
          className="add-frame-text"
          onClick={() => {
            props.setShowReturnPage(false);
          }}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z" />
            </svg>
          </span>
        </div>
      </div>

      <div className="title">Order Review ({props.selectedProductTotal})</div>

      <div className="confirm-box">
        <div className="confirm" onClick={props.placeReturnOrder}>
          CONFIRM
        </div>
      </div>

      <div className="frame-grid">
        {props.selectedProducts.map((product, index) => (
          <div className="frame-card" key={index}>
            <div className="img-box">
              <img
                src={
                  product.images && product.images.length > 0
                    ? product.images[0].replace(
                        "upload/",
                        "upload/if_w_gt_400/w_400/if_end/"
                      )
                    : ""
                }
                alt={product.name}
              />
            </div>
            <div className="product">
              <div className="">
                <div className="product-name">{product.name}</div>
                <div className="product-sku">sku:&nbsp;{product.sku}</div>
              </div>
              <div className="product-return-quantity">
                <div
                  className="return"
                  onClick={() => {
                    handleRemoveProduct(product.product_id);
                  }}
                >
                  Remove
                </div>
                <div className="quantity">{product.quantity}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ExchangeReview(props) {
  const [frameTypes, setFrameTypes] = useState("Old Frames");
  const navigate = useNavigate();
  return (
    <div className="return-review">
      <div className="update-btns">
        <div
          className="add-frame-text"
          onClick={() => {
            // props.setOperation("Return");
            props.setOperation(null);
            navigate('/inventory/in-store');
            props.setProductsToExchangeWith([]);
            props.setProductsToExchange([]);
          }}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z" />
            </svg>
          </span>
          Cancel
        </div>
      </div>

      <div className="title">
        Order Review
        {/* ({props.selectedProductTotal}) */}
      </div>

      <div className="d-flex j-sb a-e">
        <div className="container">
          <div className="store-types">
            <div
              className={
                frameTypes == "Old Frames" ? "store-type active" : "store-type"
              }
              onClick={() => {
                setFrameTypes("Old Frames");
              }}
            >
              Old Frames
            </div>
            <div
              className={
                frameTypes == "New Frames" ? "store-type active" : "store-type"
              }
              onClick={() => {
                setFrameTypes("New Frames");
              }}
            >
              New Frames
            </div>
            <div className="border w-100"></div>
          </div>
        </div>

        <div className="confirm-box">
          <div className="confirm" onClick={props.exchangeRetailerProducts}>
            CONFIRM
          </div>
        </div>
      </div>

      {frameTypes == "Old Frames" && (
        <>
        {props.productsToExchange.length <= 0 && (
          <div className="no-products">
            No products selected to Exchange 
          </div>
        )}
        <div className="frame-grid">
          {props.productsToExchange.map((product, index) => (
            <div className="frame-card" key={index}>
              <div className="img-box">
                <img
                  src={
                    product.images && product.images.length > 0
                      ? product.images[0].replace(
                          "upload/",
                          "upload/if_w_gt_400/w_400/if_end/"
                        )
                      : ""
                  }
                  alt={product.name}
                />
              </div>
              <div className="product">
                <div className="">
                  <div className="product-name">{product.name}</div>
                  <div className="product-sku">sku:&nbsp;{product.sku}</div>
                </div>
                <div className="product-return-quantity">
                  <div
                    className="return"
                    onClick={() => {
                      // handleRemoveProduct(product.product_id)
                      props.handleRemoveProductFromOldExchangeProducts(
                        product.product_id
                      );
                    }}
                  >
                    Remove
                  </div>
                  <div className="quantity">{product.quantity}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>
      )}

      {frameTypes == "New Frames" && (
        <>
        {props.productsToExchangeWith.length <= 0 && (
          <div className="no-products">
            No products selected to Exchange with
          </div>
        )}
        <div className="frame-grid">
          {props.productsToExchangeWith.map((product, index) => (
            <div className="frame-card" key={index}>
              <div className="img-box">
                <img
                  src={
                    product.images && product.images.length > 0
                      ? product.images[0].replace(
                          "upload/",
                          "upload/if_w_gt_400/w_400/if_end/"
                        )
                      : ""
                  }
                  alt={product.name}
                />
              </div>
              <div className="product">
                <div className="">
                  <div className="product-name">{product.name}</div>
                  <div className="product-sku">sku:&nbsp;{product.sku}</div>
                </div>
                <div className="product-return-quantity">
                  <div
                    className="return"
                    onClick={() => {
                      // handleRemoveProduct(product.product_id)
                      props.handleRemoveProductFromNewExchangeProducts(
                        product.product_id
                      );
                    }}
                  >
                    Remove
                  </div>
                  <div className="quantity">{product.quantity}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>
      )}
    </div>
  );
}

//const variant selection popUP

const SelectionPopup = (props) => {
  const [count, setCount] = useState(0);
  const [maxCount, setMaxCount] = useState(10);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const increment = () => {
    if (count < maxCount) {
      setCount(count + 1);
    }
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const product = {
    product: {
      prices: {
        price: 1980,
        originalPrice: 1980,
        discount: 0,
      },
      categories: [
        {
          _id: "62c827b5a427b63741da9175",
          name: {
            en: "Home",
          },
        },
      ],
      image: [
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714459636/product/BR100749-19-135C6.png",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714652108/product/BR100749-19-135C6%282%29.png",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714652107/product/BR100749-19-135C6%281%29.png",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714462652/product/BR100749-19-135C7.png",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714562835/product/BR100749-19-135C7%281%29.jpg",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714562835/product/BR100749-19-135C7%282%29.jpg",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714460440/product/BR100749-19-135C8.png",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714653042/product/BR100749-19-135C8%28%29.png",
        "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714653042/product/BR100749-19-135C8%282%29.png",
      ],
      tag: ['["Home"]'],
      variants: [
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b3",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b7",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 10,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-0",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714460440/product/BR100749-19-135C8.png",
          options: [
            {
              Color: "Red",
            },
            {
              Size: "Small",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b4",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b7",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 2,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-1",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714459636/product/BR100749-19-135C6.png",
          options: [
            {
              Color: "Green",
            },
            {
              Size: "Small",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b5",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b7",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 3,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-2",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714462652/product/BR100749-19-135C7.png",
          options: [
            {
              Color: "Blue",
            },
            {
              Size: "Small",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b3",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b8",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 1,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-3",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714653042/product/BR100749-19-135C8%28%29.png",
          options: [
            {
              Color: "Red",
            },
            {
              Size: "Medium",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b4",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b8",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 2,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-4",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714652108/product/BR100749-19-135C6%282%29.png",
          options: [
            {
              Color: "Green",
            },
            {
              Size: "Medium",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b5",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b8",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 3,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-5",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714562835/product/BR100749-19-135C7%281%29.jpg",
          options: [
            {
              Color: "Blue",
            },
            {
              Size: "Medium",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b3",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b9",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 1,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-6",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714653042/product/BR100749-19-135C8%282%29.png",
          options: [
            {
              Color: "Red",
            },
            {
              Size: "Large",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b4",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b9",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1970.00",
          quantity: 2,
          discount: "10.00",
          productId: "6635d9fb639f0ac175a59444-7",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714652108/product/BR100749-19-135C6%282%29.png",
          options: [
            {
              Color: "Green",
            },
            {
              Size: "Large",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b5",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b9",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b38",
          originalPrice: "1980.00",
          price: "1900.00",
          quantity: 3,
          discount: "80.00",
          productId: "6635d9fb639f0ac175a59444-8",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714562835/product/BR100749-19-135C7%282%29.jpg",
          options: [
            {
              Color: "Blue",
            },
            {
              Size: "Large",
            },
            {
              Material: "Steel",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b4",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b7",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 2,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-10",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714652108/product/BR100749-19-135C6%282%29.png",
          options: [
            {
              Color: "Green",
            },
            {
              Size: "Small",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b5",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b7",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 3,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-11",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714562835/product/BR100749-19-135C7%281%29.jpg",
          options: [
            {
              Color: "Blue",
            },
            {
              Size: "Small",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b3",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b8",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 1,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-12",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714460440/product/BR100749-19-135C8.png",
          options: [
            {
              Color: "Red",
            },
            {
              Size: "Medium",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b4",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b8",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 1,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-13",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714459636/product/BR100749-19-135C6.png",
          options: [
            {
              Color: "Green",
            },
            {
              Size: "Medium",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b5",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b8",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 1,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-14",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714462652/product/BR100749-19-135C7.png",
          options: [
            {
              Color: "Blue",
            },
            {
              Size: "Medium",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b3",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b9",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 1,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-15",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714653042/product/BR100749-19-135C8%282%29.png",
          options: [
            {
              Color: "Red",
            },
            {
              Size: "Large",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b4",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b9",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 1,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-16",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714459636/product/BR100749-19-135C6.png",
          options: [
            {
              Color: "Green",
            },
            {
              Size: "Large",
            },
            {
              Material: "Plastic",
            },
          ],
        },
        {
          "63f078f54b86ed26b05281b2": "63f078f54b86ed26b05281b5",
          "63f078f54b86ed26b05281b6": "63f078f54b86ed26b05281b9",
          "6631f3c1431a177a38e77b37": "6631f3c1431a177a38e77b39",
          originalPrice: "1980.00",
          price: "1780.00",
          quantity: 0,
          discount: "200.00",
          productId: "6635d9fb639f0ac175a59444-17",
          barcode: "NTC1230QC",
          sku: "NTC1234QA",
          image:
            "https://res.cloudinary.com/dzsnzv0at/image/upload/v1714462652/product/BR100749-19-135C7.png",
          options: [
            {
              Color: "Blue",
            },
            {
              Size: "Large",
            },
            {
              Material: "Plastic",
            },
          ],
        },
      ],
      attributes: [
        {
          "Material Details": [
            {
              "63f078f54b86ed26b05281b6": "66176eb608ffb5410c013e6f",
              attributeGroup: "Material Details",
              attributeName: "Gross Weight",
              attributeValue: "20 GM",
              status: "show",
              type: "Text",
            },
            {
              "63f078f54b86ed26b05281b6": "66176ed008ffb5410c013e7b",
              attributeGroup: "Material Details",
              attributeName: "Net Weight",
              attributeValue: "18 GM",
              status: "show",
              type: "Text",
            },
            {
              "63f078f54b86ed26b05281b6": "66177643ed299a4ca81749e4",
              attributeGroup: "Material Details",
              attributeName: "Color",
              attributeValue: "Black ",
              status: "show",
              type: "Text",
            },
          ],
          "Frame Details": [
            {
              "6631f436431a177a38e77b6b": "6631f436431a177a38e77b6c",
              attributeGroup: "Frame Details",
              attributeName: "Frame Type",
              attributeValue: "Full Rim",
              status: "show",
              type: "Text",
            },
            {
              "6631f436431a177a38e77b6b": "6631f455431a177a38e77b7b",
              attributeGroup: "Frame Details",
              attributeName: "Frame Shape",
              attributeValue: "Aviator",
              status: "show",
              type: "Text",
            },
            {
              "6631f436431a177a38e77b6b": "6631f462431a177a38e77b88",
              attributeGroup: "Frame Details",
              attributeName: "Frame Size",
              attributeValue: "Medium",
              status: "show",
              type: "Text",
            },
          ],
        },
      ],
      status: "show",
      _id: "6635d9fb639f0ac175a59445",
      productId: "6635d9fb639f0ac175a59444",
      sku: "NTC1234QA",
      barcode: "NTC1230QC",
      title: {
        en: "Netre Variant Product Test",
      },
      description: {
        en: "Brand Name - Netre Color Polarized\nProduct Type - Eye's Glass\nFrame Type - Full Rim\nFrame Shape - Aviator\nModel No. - NT11075\nFrame Size - Medium\nFrame Width - 128 mm\nFrame Dimensions - 52-16-130\nFrame colour -Fully Colour\nGlass Color - Gold\nWeight - 17 gm\nWeight Group - Average\nMaterial -Stainless Steel\nFrame Material - Stainless Steel",
      },
      slug: "netre-variant-product-test",
      category: {
        _id: "62c827b5a427b63741da9175",
        name: {
          en: "Home",
        },
      },
      stock: 24,
      isCombination: true,
      createdAt: "2024-05-04T06:47:23.485Z",
      updatedAt: "2024-05-07T10:46:17.044Z",
      __v: 12,
      sales: 17,
      options: [
        {
          Color: [
            {
              optionName: "Color",
              optionValue: "Red",
            },
            {
              optionName: "Color",
              optionValue: "Green",
            },
            {
              optionName: "Color",
              optionValue: "Blue",
            },
          ],
          Size: [
            {
              optionName: "Size",
              optionValue: "Small",
            },
            {
              optionName: "Size",
              optionValue: "Medium",
            },
            {
              optionName: "Size",
              optionValue: "Large",
            },
          ],
          Material: [
            {
              optionName: "Material",
              optionValue: "Steel",
            },
            {
              optionName: "Material",
              optionValue: "Plastic",
            },
          ],
        },
      ],
    },
  };
  const isCombination = product.product.isCombination;
  const options = isCombination ? product.product.options : [];
  const variants = isCombination ? product.product.variants : [];
  useEffect(() => {
    setSelectedVariant(variants[0]);
  }, []);
  return (
    <div className="selection-popup">
      <div className="backdrop"></div>
      <div className="container">
        <div className="close">X</div>
        <div className="title">Select Varient</div>
        <div className="box">
          <div className="left">
            <div className="product-box">
              <div className="img">
                {!isCombination && (
                  <img
                    src={product.product.image[0].replace(
                      "upload/",
                      "upload/if_w_gt_400/w_400/if_end/"
                    )}
                    alt={product.product.title.en}
                  />
                )}
                {isCombination && (
                  <img
                    src={selectedVariant?.image.replace(
                      "upload/",
                      "upload/if_w_gt_400/w_400/if_end/"
                    )}
                    alt={product.product.title.en}
                  />
                )}
              </div>
              <div className="details">
                <div className="name">{product.product.title.en}</div>
                <div className="sku">SKU: {product.product.sku}</div>
                <div className="price">
                  Price: {product.product.prices.price}
                </div>
              </div>
              <div className="add">
                {count > 0 && (
                  <div className="counter">
                    <div className="minus" onClick={decrement}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#5f6368"
                      >
                        <path d="M240-460v-40h480v40H240Z" />
                      </svg>
                    </div>
                    <div className="quantity">{count}</div>
                    <div className="plus" onClick={increment}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#5f6368"
                      >
                        <path d="M450-450H220v-60h230v-230h60v230h230v60H510v230h-60v-230Z" />
                      </svg>
                    </div>
                  </div>
                )}
                {count <= 0 && (
                  <div className="add-btn" onClick={increment}>
                    ADD
                  </div>
                )}
              </div>
            </div>
            <div className="options-box"></div>
            <div className="final">
              <div className="confirm-btn">CONFIRM</div>
              <div className="total">Total: 0</div>
            </div>
          </div>
          <div className="right">
            {variants.map((product, index) => (
              <div className="frame-card" key={index}>
                <div className="img-box">
                  <img
                    src={
                      product.images && product.images.length > 0
                        ? product.images[0].replace(
                            "upload/",
                            "upload/if_w_gt_400/w_400/if_end/"
                          )
                        : ""
                    }
                    alt={product.name}
                  />
                </div>
                <div className="product">
                  <div className="">
                    <div className="product-name">{product.name}</div>
                    <div className="product-sku">sku:&nbsp;{product.sku}</div>
                  </div>
                  <div className="product-return-quantity">
                    <div className="return">Remove</div>
                    <div className="quantity">{product.quantity}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// "product_id":"644501ab7094a0000851284b",
// "name": "Product 1",
// "images": ["url1", "url2"],
// "SKU": "SKU123",
// "quantity": 5,
// "price": 10.99,
// "variants": [],
// "attributes": [],
// "orderStatus":"Placed"
// ------------------------

// "product_id":"644501ab7094a0000851284b",
// "name": "Product 1",
// "images": ["url1", "url2"],
// "SKU": "SKU123",
// "quantity": 5,
// "price": 10.99,
// "variants": [],
// "attributes": [],
// "orderStatus":"Placed"
