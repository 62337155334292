import React from 'react'
import './Profile.scss'
import { Link } from 'react-router-dom'
import inquryImg from '../../assets/icons/hand-showing-inquiry-word-through-260nw-367776749.jpg'
function Profile() {
  return (
    <div className='profile'>
      <h3>Profile</h3>
      {/* grid of 3 column square ratio each item a button at bottom half and a picture filled in item */}
      <div className='profile-grid'>
        <Link to="/account" className='profile-item'>
            <img src="https://i.insider.com/5d166b222516e9139b26217c?width=700" alt="" />
            <button>Profile Info</button>
        </Link>
        <Link to="/orders/history" className='profile-item'>
            <img src="https://www.elotouch.com/media/magearray/news/image/Elo_Blog_Post_Gallery_Crazybowl.jpg" alt="" />
            <button>Order History</button>
        </Link>
        <Link to="/policy" className='profile-item'>
            <img src="https://media.istockphoto.com/id/1364971537/photo/privacy-policy-notice-and-legal-agreement.jpg?s=612x612&w=0&k=20&c=8IENM8HehuIOnYMH5uN4E1OKY7aU3qoRZ7OjeeHi0rI=" alt="" />
            <button>Privacy Policy</button>
        </Link>
        <Link to="/inquiries" className='profile-item'>
            <img src={inquryImg} alt="Inquiry" />
            <button>Inquiries</button>
        </Link>

      </div>
    </div>
  )
}

export default Profile