import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTopPage(props) {
  // main scroll to top component
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    //scroll right-area to top
    const rightArea = document.querySelector('.right-area');
    if (rightArea) {
      rightArea.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }

  }, [pathname]);

  return null;
}
