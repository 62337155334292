import React, { useEffect, useState } from 'react'
// import '../retailerOrderHistory/RetailerOrderHistory.scss'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getUser } from '../../home/Home';
import { useGetCustomerListOfRetailerQuery } from '../../features/order/cutomerOrderSlice';
import { OrderCard } from '../completedOrders/CompletedOrders';
import './CustomerList.scss'
export default function CustomerList() {
  //login
  const [user, setUser] = useState(getUser());
  
  const navigate = useNavigate();
  useEffect(()=>{
    if(!user){
      navigate('/login')
    }
  },[user])
  //login end
  
  
  const {
    data: CustomerListOfRetailerData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCustomerListOfRetailerQuery({bearerToken:user})
  if(isError && error.status === 401){
    navigate('/login');
  }
  const [orderType,  setOrderType] = useState("All");
  const [selectedTab, setSelectedTab] = useState('All');
  const tabNames = ['All', 'Online', 'In store'];
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setOrderType(tab);
  };
  CustomerListOfRetailerData && console.log(CustomerListOfRetailerData.data)
  const filterData = () => {
    if (!CustomerListOfRetailerData?.data) return [];
    switch (selectedTab) {
      case 'All':
        return CustomerListOfRetailerData?.data
        // return CustomerListOfRetailerData?.data.filter(order => order.status === 'online' || order.status === 'instore');
      case 'Online':
        return CustomerListOfRetailerData?.data.filter(order => order.status === 'online');
      case 'In store':
        return CustomerListOfRetailerData?.data.filter(order => order.status === 'instore');
      default:
        return CustomerListOfRetailerData?.data;
        // return null;
    }
  };

    // Render based on the selected tab
  let displayedData = [];
  if (isSuccess) {
    displayedData = filterData();
    console.log(displayedData)
  }

  const [singleOrderData, setSingleOrderData] = useState(null);
  const [showOrderCard, setShowOrderCard] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className='CompletedOrders RetailerOrders CustomerList'>
      {/* navigation */}
      {!showOrderCard && 
        <>
        {/* <div className="container">
        <div className="store-types">
        {tabNames.map(tab => (
          <div
            key={tab}
            className={orderType === tab ? 'store-type active' : 'store-type'}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
        <div className="border"></div>
      </div>
      </div> */}
      <div className="orders">
      {displayedData.length === 0 ? (
          <p>No customers to show.</p>
        ) : (
          [...displayedData].map(item => (
            <CustomerOrderHistory data={item} key={item._id} setSingleOrderData={setSingleOrderData} setShowOrderCard={setShowOrderCard}/>
          ))
        )}
      </div>
      </>}
      {showOrderCard && <div className='add-frame-text' 
      onClick={()=>{
        setShowOrderCard(false);
        if (searchParams.has('s')) {
          searchParams.delete('s');
          setSearchParams(searchParams);
        }
      }}
      ><span><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg></span> <span className='mont-regular'>Customers</span> </div>}
      {showOrderCard && singleOrderData && 
      <div className="Single-order-data">
        <div className="name">{singleOrderData?.firstname}</div>
        <div className="datagrid">
          <div className="grid-item">
            {/* <div className="sub-item">
              <div className="label">Customer Type:</div>
              <div className="data">{singleOrderData?.type}</div>
            </div> */}
            <div className="sub-item">
              <div className="label">Phone No:</div>
              <div className="data">{singleOrderData?.phone}</div>
            </div>
            <div className="sub-item">
              <div className="label">Email:</div>
              <div className="data">{singleOrderData?.email}</div>
            </div>
          </div>
          <div className="grid-item">
          <div className="sub-item address">
              <div className="label">Address:</div>
              <div className="data">{singleOrderData?.addresses[0]?.address1}, {singleOrderData?.addresses[0]?.address2}, {singleOrderData?.addresses[0]?.city}, {singleOrderData?.addresses[0]?.zipCode}</div>
            </div>
          </div>
          <div className="grid-item">
            <div className="sub-item">
                <div className="label">Total Orders</div>
                <div className="data">{singleOrderData?.orders.length}</div>
              </div>
          </div>
        </div>
        <h4>List of Orders</h4>
        <div className="orders">
       {singleOrderData?.orders?.length === 0 ? (
          <p>No products to show.</p>
        ) : 
        // (
        //   [...singleOrderData?.orders].reverse().map(item => (
        //     <OrderCard data={item} key={item._id} />
        //   ))
        // )
        (
          [...singleOrderData?.orders].reverse().map(item => {
            return item.cart.map((cartItem, index) => {
              if (index === 0) {
                return <OrderCard data={{...item, cart: [cartItem]}} key={item._id} />
              } else {
                return <OrderCard data={{...item, cart: [cartItem]}} key={item._id + index} />
              }
            })
          })
        )
        }
       </div>
      </div>
      }
    </div>
  )
}


const CustomerOrderHistory = ({data, setSingleOrderData,setShowOrderCard}) => {
  const [singleData, setSingleData] = useState(null);
  const handleClick = () => {
    setSingleOrderData(singleData);
    setShowOrderCard(true);
  }
  useEffect(()=>{
    setSingleData(data);
  },[data])
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("s");
  console.log("location.pathname============", location.pathname)
  useEffect(()=>{
    console.log("new id received", id)
    if(singleData && data && id === data._id ){
      console.log("foundddddd", data?.firstname);
      handleClick();
    }
  }, [id, data, singleData, location.pathname])


  return (
    <div className='order-history-card' onClick={handleClick}>
      <div className='order-details'>
        <div className='order-details-item order-name'>
            <div className="label">Customer name</div>
            <div className="data mont-bold">{data?.firstname}</div>
        </div>
        <div className='order-details-item order-status'>
          <div className="label"> Phone No. </div>
          <div className="data">{data?.phone}</div>
        </div>
        <div className='order-details-item order-frames'> 
          <div className="label"> Email </div>
          <div className="data">{data?.email}</div>
        </div>
        <div className='order-details-item order-status'>
          {data?.addresses && data?.addresses.length > 0 && 
          <>
            <div className="label"> Address </div>
            <div className="data">{data?.addresses[0].address1}</div>
          </>}
        </div>
        {/* <div className='order-details-item order-cancel'>cancel order</div> */}
        

      </div>
      <div className='order-products'>
        {/* {data.products.map(product => (
          <div className='product'>
            <div className='product-name'>{product.name}</div>
            <div className='product-quantity'>Quantity: {product.quantity}</div>
          </div>
        ))} */}
      </div>
    </div>
  )
}
