import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { getUser } from "../../home/Home";
import { useNavigate } from "react-router-dom";
import { useAddInquiryMutation, useGetRetailerInfoQuery } from "../../features/product/productSlice";
import { addHomeInquiryProducts, removeProductFromHomeInquiry, setHomeInquiryCustomer } from "../../features/order/homeInquirySlice";
import { addStoreInquiryProducts, removeProductFromStoreInquiry, setStoreInquiryCustomer } from "../../features/order/storeInquirySlice";

export const InquiryReview = props => {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user]); //login end

  const [showInquiryReview, setShowInquiryReview] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  useEffect(() => {
    props.setShowInquiryReview(showInquiryReview);
  }, [showInquiryReview]);
  useEffect(() => {
    setShowInquiryReview(props.showInquiryReview);
  }, [props.showInquiryReview]);
  const [addInquiry, {
    data: addInquiryData,
    isLoading: addInquiryIsLoading,
    isSuccess: addInquiryIsSuccess,
    isError: addInquiryIsError,
    error: addInquiryError
  }] = useAddInquiryMutation();
  const {
    data: retailerData,
    isLoading: isLoadingRetailer,
    isSuccess: isSuccessRetailer,
    isError: isErrorRetailer,
    error: errorRetailer
  } = useGetRetailerInfoQuery({
    bearerToken: user
  });
  console.log("retailer", retailerData);
  const [orderType, setOrderType] = useState(props.orderType || "Home Inquiry");
  const [selectedTab, setSelectedTab] = useState(props.orderType || "Home Inquiry");
  const tabNames = ['Home Inquiry', 'Store Inquiry'];

  const handleTabClick = tab => {
    setSelectedTab(tab);
    setOrderType(tab);
  };

  const dispatch = useDispatch();
  const homeInquiry = useSelector(state => state.homeInquiry);
  const storeInquiry = useSelector(state => state.storeInquiry);
  const [products, setProducts] = useState([]);
  const homeInquiryCustomer = useSelector(state => state.homeInquiry.customer);
  const storeInquiryCustomer = useSelector(state => state.storeInquiry.customer);
  const homeInquiryCustomerPhone = homeInquiryCustomer?.phone || "";
  const homeInquiryCustomerId = homeInquiryCustomer?._id || "";
  useEffect(() => {
    if (orderType == "Home Inquiry") {
      setProducts(homeInquiry.products);
    } else if (orderType == "Store Inquiry") {
      setProducts(storeInquiry.products);
    }
  }, [homeInquiry, storeInquiry, orderType]);

  const handleRemoveProductFromCart = product => {
    const filteredProducts = products.filter(item => item !== product);
    setProducts(filteredProducts);
  };

  const placeInquiry = () => {
    if (orderType == "Home Inquiry") {
      if(homeInquiry.products.length === 0) {
        toast.error("Please add products to inquiry tray", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce
        });
        return;
      }
      
      const body = {
        "products": homeInquiry.products,
        "address": {
          "_id": homeInquiryCustomer?._id || "",
          "firstname": homeInquiryCustomer?.firstname || "",
          "lastname": homeInquiryCustomer?.lastname || "",
          "phone": homeInquiryCustomer?.phone || "",
          "address1": homeInquiryCustomer?.addresses[0]?.address1 || "",
          "address2": homeInquiryCustomer?.addresses[0]?.address2 || "",
          "country": "",
          "city": homeInquiryCustomer?.addresses[0]?.city || "",
          "zipCode": homeInquiryCustomer?.addresses[0]?.zipCode || ""
        },
        "phoneNumber": homeInquiryCustomer.phone,
        "storeId": retailerData.data.uniqueStoreId,
        "status": "Placed",
        "type": "Home",
        "customerId": homeInquiryCustomer?._id
      };
      console.log("home inquiry", body);
      addInquiry({
        body: body,
        bearerToken: user
      }).then(res => {
        console.log("res", res);

        if (res.data) {
          dispatch(addHomeInquiryProducts([]));
          dispatch(setHomeInquiryCustomer(null))
          toast.success("Inquiry placed successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce
          });
          navigate('/virtual-tryon');
        }
      });
    } else if (orderType == "Store Inquiry") {
      if(storeInquiry.products.length === 0) {
        toast.error("Please add products to inquiry tray", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce
        });
        return;
      }
      const body = {
        "products": storeInquiry.products,
        "address": {
          "_id": "",
          "firstname": retailerData.data.retailerName || "",
          "lastname":  "",
          "phone": retailerData.data.phoneNumber || "",
          "address1": retailerData.data.address || "",
          "address2":  "",
          "country": "",
          "city":  "",
          "zipCode": retailerData.data.zipcode || ""
        },
        "phoneNumber": retailerData.data.phoneNumber,
        "storeId": retailerData.data.uniqueStoreId,
        "status": "Placed",
        "type": "Store",
      };
      console.log("store inquiry", body); 
      addInquiry({
        body: body,
        bearerToken: user
      }).then(res => {
        console.log("res", res);
        if (res.data) {
          dispatch(addStoreInquiryProducts([]));
          dispatch(setStoreInquiryCustomer(null))
          toast.success("Inquiry placed successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce
          });
          navigate('/virtual-tryon');
        }
      });
    }
  };

  return <div className="InquiryReview">
        
        {showInquiryReview && !showCheckout && <div className="review">
          <div className="left" onClick={() => {
        setShowInquiryReview(false);
        props.setShowInquiryTray(true);
        props.setShowProductPage(true);
          }}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg>
              <div>Select frames you want to inquire</div>    
          </div>
          <div className="title">Inquiry Tray</div>
          {
        /* navigation */
      }
          <div className="container d-flex j-sb">
              <div className="store-types">
              {tabNames.map(tab => <div key={tab} className={orderType === tab ? 'store-type active' : 'store-type'} onClick={() => handleTabClick(tab)}>
                  {tab}
                </div>)}
              <div className="border"></div>
              </div>
              <div className="confirm-box">
              {orderType == "Home Inquiry" && <div className="confirm" onClick={() => {
                      if(homeInquiry.products.length === 0) {
                        toast.error("Please add products to inquiry tray", {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          transition: Bounce
                        });
                        return;
                        }
                setShowCheckout(true);
                }}>CONFIRM</div>}
              {orderType == "Store Inquiry" && <div className="confirm" onClick={() => {placeInquiry();}}>CONFIRM</div>}
              </div>
          </div>


          {orderType == "Home Inquiry" && <div className="frame-grid">
            {homeInquiry.products.length === 0 && <div className="empty-tray">No products to show</div>} 
            {homeInquiry.products.map((product, index) => <div className="frame-card" key={index}>
              <div className="img-box">
                <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : ""} alt={product.name} />
              </div>
              <div className='product'>
                <div className="">
                <div className='product-name'>{product.name}</div>
                <div className='product-sku'>sku:&nbsp;{product.sku}</div>
                <div className="p-vr">{product?.phoneNumber}</div>
                </div>
                <div className='product-return-quantity'><div className="return" onClick={() => {
                dispatch(removeProductFromHomeInquiry(product.product_id));
              }}>Remove</div>
              <div className="quantity"></div></div>
              </div>
            </div>)}
          </div>}


          {orderType == "Store Inquiry" && <div className="frame-grid">
            {storeInquiry.products.length === 0 && <div className="empty-tray">No products to show</div>}
            {storeInquiry.products.map((product, index) => <div className="frame-card" key={index}>
              <div className="img-box">
                <img src={product.images && product.images.length > 0 ? product.images[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : ""} alt={product.name} />
              </div>
              <div className='product'>
                <div className="">
                <div className='product-name'>{product.name}</div>
                <div className='product-sku'>sku:&nbsp;{product.sku}</div>
                <div className="p-vr">{product?.phoneNumber}</div>
                </div>
                <div className='product-return-quantity'><div className="return" onClick={() => {
                dispatch(removeProductFromStoreInquiry(product._id));
              }}>Remove</div><div className="quantity"></div></div>
              </div>
            </div>)}
          </div>}


        </div>}
        {showCheckout && <div className="home-enquiry-form">
        <div className="left" onClick={() => {
        setShowCheckout(false);
        setShowInquiryReview(true);
      }}>

              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z"/></svg>
              <div>Inquiry Tray</div>    
          </div>
          <div className="customer-details">
            <div className="title">Customer details</div>
            <input type="text" placeholder='Mobile No.' className='mobile-input' name='phone' value={homeInquiryCustomer?.phone} readOnly // onChange={customerFormik.handleChange}
        // value={customerFormik.values.phone}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter' || e.key === 'Tab') {
        //     customerFormik.handleSubmit()
        //   }
        // }}
        />
            
            {
          /* {!showOTP && <span className="add-mobile pointer" onClick={()=>{customerFormik.handleSubmit()}}>Add</span>} */
        }
            {
          /* {showOTP && <span className="add-mobile pointer" onClick={sendOTP}>Send OTP</span>} */
        }
            {
          /* {customerFormik.errors.phone  ? <div className="error">{customerFormik.errors.phone}</div> : null} */
        }


            {
          /* {otp && checkoutType === "customer" && <span className="add-mobile pointer" onClick={registerFormik.handleSubmit}>Register</span>}
          {otp && checkoutType === "customer" && registerFormik.errors.otp  ? <div className="error">{registerFormik.errors.otp}</div> : null} */
        }
            <input type="text" placeholder='First Name' className='mobile-input' name='firstname' value={homeInquiryCustomer?.firstname} readOnly // value={registerFormik.values.firstname}
        // onChange={registerFormik.handleChange}
        />
            {
          /* {registerFormik.errors.firstname  ? <div className="error">{registerFormik.errors.firstname}</div> : null} */
        }
            <input type="text" placeholder='Last Name' className='mobile-input' name='lastname' value={homeInquiryCustomer?.lastname} readOnly // value={registerFormik.values.lastname}
        // onChange={registerFormik.handleChange}
        />
            {
          /* {checkoutType === "customer" && registerFormik.errors.lastname  ? <div className="error">{registerFormik.errors.lastname}</div> : null} */
        }


          </div>

          <div className="billing-details">
            <div className="title">Billing details</div>
            <div className="billing-grid">
              <input className='item-1' type="text" placeholder='Address 1' name='address1' value={homeInquiryCustomer?.addresses[0]?.address1} readOnly // onChange={adressFormik.handleChange}
          // value={adressFormik.values.address1}
          />
              {
            /* {adressFormik.errors.address1  ? <div className="error">{adressFormik.errors.address1}</div> : null} */
          }
              <input className='item-2' type="text" placeholder='Address 2' name='address2' value={homeInquiryCustomer?.addresses[0]?.address2} readOnly // onChange={adressFormik.handleChange}
          // value={adressFormik.values.address2}
          />
              {
            /* {adressFormik.errors.address2  ? <div className="error">{adressFormik.errors.address2}</div> : null} */
          }
              <input className='item-2' type="text" placeholder='City' name='city' value={homeInquiryCustomer?.addresses[0]?.city} readOnly // onChange={adressFormik.handleChange}
          // value={adressFormik.values.city}
          />
                {
            /* {adressFormik.errors.city  ? <div className="error">{adressFormik.errors.city}</div> : null} */
          }
              <input className='item-2' type="text" placeholder='Pincode' name='zipCode' value={homeInquiryCustomer?.addresses[0]?.zipCode} readOnly // onChange={adressFormik.handleChange}
          // value={adressFormik.values.zipCode}
          />
              {
            /* {adressFormik.errors.zipCode  ? <div className="error">{adressFormik.errors.zipCode}</div> : null} */
          }
            </div>
          </div>

          {products.length > 0 && <div className="order-summary"> 
          {orderType == "Home Inquiry" && <div className="title mont-bold">Home Inquiry Tray</div>}
          {orderType == "Store Inquiry" && <div className="title mont-bold">Store Inquiry Tray</div>}
            {[...products].reverse().map((item, index) => <div className="container">
            <div className="box" key={index}>
              <div className="img-box">
                <img src={item.images ? item?.images[0].replace("upload/", "upload/if_w_gt_400/w_400/if_end/") : ""} />
              </div>
              <div className="desc">
                <div className="title">{item?.name}</div>
                <div className="detail">
                <div className="stock">SKU:&nbsp; {item?.sku}</div>
                  <div className="stock">Qty:&nbsp; 1</div>

                </div>
              </div>
            </div>
            {
            /* <div className="total">
             <div className="delete-btn" onClick={()=>{handleRemoveProductFromCart(item)}}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M304.62-160q-26.85 0-45.74-18.88Q240-197.77 240-224.62V-720h-40v-40h160v-30.77h240V-760h160v40h-40v495.38q0 27.62-18.5 46.12Q683-160 655.38-160H304.62ZM680-720H280v495.38q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h350.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93V-720ZM392.31-280h40v-360h-40v360Zm135.38 0h40v-360h-40v360ZM280-720v520-520Z"/></svg></div>
             <div className="">&#8377;&nbsp;{item?.price}</div>
             </div> */
          }
            
          </div>)}
        
        </div>}

        <div className="place-order">
          <div className='btn' onClick={placeInquiry}>PLACE INQUIRY</div>
        </div>

        </div>}
      </div>;
};
  