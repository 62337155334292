import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchQuery: '',
    showSearch: false,
  },
  reducers: {
    setSearchQuery: (state, action) => {
        state.searchQuery = action.payload;
    },
    setShowSearch: (state, action) => {
        state.showSearch = action.payload;
    },
  },
});

export const {
    setSearchQuery,
    setShowSearch
} = searchSlice.actions;
export default searchSlice.reducer;