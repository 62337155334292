import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { productSlice } from "../features/product/productSlice";
import { customerOrderSlice } from "../features/order/cutomerOrderSlice";
import userReducer from "../features/user/userSlice"
import orderReducer from "../features/order/orderSlice"
import homeInquiryReducer from "../features/order/homeInquirySlice"
import storeInquiryReducer from "../features/order/storeInquirySlice"
import selectedProductsReducer from '../features/inventory/selectedProductsSlice';
import searchReducer from "../features/search/searchSlice"
import { setupListeners } from "@reduxjs/toolkit/query";

const rootReducer = combineReducers({
    user: userReducer,
    order:orderReducer,
    homeInquiry: homeInquiryReducer,
    storeInquiry: storeInquiryReducer,
    search: searchReducer,
    selectedProducts: selectedProductsReducer,
    [productSlice.reducerPath]: productSlice.reducer,
    [customerOrderSlice.reducerPath]: customerOrderSlice.reducer,
  });
  
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productSlice.middleware).concat(customerOrderSlice.middleware),
})
setupListeners(store.dispatch);