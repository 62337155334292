import React, { useRef } from "react";

const PDFPreview = ({ showModel, setShowModel, setIAgree }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModel(false);
    }
  };

  return (
    <>
      {showModel ? (
        <>
          <div class="modal-overlay" onClick={handleClickOutside}>
            <div class="modal-content" ref={modalRef}>
              <div class="modal-header">
                <h3>Terms & Conditions</h3>
                <button
                  type="button"
                  class="close-button"
                  data-modal-toggle="crud-modal"
                >
                  <div
                    className="material-symbols-outlined clear_ic"
                    onClick={() => setShowModel(!showModel)}
                  >
                    close
                  </div>
                </button>
              </div>
              <div class="modal-body">
                <iframe
                  src={
                    "https://docs.google.com/viewer?embedded=true&url=https%3A%2F%2Fapi.netre.in%2FB2B%2520Agreement%2520and%2520T%26C.pdf"
                  }
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  title="PDF Viewer"
                >
                  This browser does not support PDFs. Please download the PDF to
                  view it:
                  <a
                    href={
                      "https://docs.google.com/viewer?embedded=true&url=https%3A%2F%2Fapi.netre.in%2FB2B%2520Agreement%2520and%2520T%26C.pdf"
                    }
                  >
                    Download PDF
                  </a>
                </iframe>
              </div>
              <div
                className="agree_btn"
                onClick={() => {
                  setShowModel(false);
                  setIAgree(true);
                }}
              >
                Agree
              </div>
            </div>
          </div>
          <div class="background-overlay"></div>
        </>
      ) : null}
    </>
  );
};

export default PDFPreview;
